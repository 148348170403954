import {
    SET_SURVEY_LOAD_ID,
    SET_SURVEY_CONFIG,
    UPDATE_SURVEY_FROM_EDITOR,
    SET_SURVEY_DATA,
    SET_SURVEY_ERROR,
    CLEAR_SURVEY_ERROR,
    SET_SHOW_PAGE_ERRORS
} from './actionTypes';

import { EditComboName, SubPageName, Fld } from '../../types/ui/EditCombo';
import { SurveyContext, SurveyError, SurveyAction } from '../reducers/survey';

import { Survey } from '../../models/survey';
import UserType from '../../models/user';

// prettier-ignore
export const setSurveyLoadId = (context: SurveyContext, loadId: string): SurveyAction => ({ type: SET_SURVEY_LOAD_ID, context, loadId });

export const setSurveyConfig = (config: Survey | null): SurveyAction => ({ type: SET_SURVEY_CONFIG, config });

// prettier-ignore
export const updateSurveyFromEditor = (editComboId: EditComboName, subPageId: SubPageName, id: string, fld: Fld, path: string, data: Survey): SurveyAction => ({
    type: UPDATE_SURVEY_FROM_EDITOR, editComboId, subPageId, id, fld, path, data
});

export const setSurveyData = (location: 'db' | 'local', data: UserType): SurveyAction => ({
    type: SET_SURVEY_DATA,
    location,
    data
});

export const setSurveyError = (error: SurveyError): SurveyAction => ({ type: SET_SURVEY_ERROR, error });

export const clearSurveyError = (path: string): SurveyAction => ({ type: CLEAR_SURVEY_ERROR, path });

export const setShowPageErrors = (pageName: string, show: boolean): SurveyAction => ({
    type: SET_SHOW_PAGE_ERRORS,
    pageName,
    show
});
