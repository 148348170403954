import { Socket } from 'socket.io-client';
import * as actionTypes from './actionTypes';

import { ChannelName, EventName, Alert, CommAction } from '../reducers/comm';

export const setSocketConnecting = (channelName: ChannelName, socketConnecting: boolean): CommAction => ({
    type: actionTypes.SET_SOCKET_CONNECTING,
    channelName,
    socketConnecting
});

export const setSocket = (channelName: ChannelName, socket: Socket | null): CommAction => ({
    type: actionTypes.SET_SOCKET,
    channelName,
    socket
});

// eslint-disable-next-line
export const setSocketEventData = (channelName: ChannelName, eventName: EventName, eventMessage: any): CommAction => ({
    type: actionTypes.SET_SOCKET_EVENT_DATA,
    channelName,
    eventName,
    eventMessage
});

export const setAlert = (alert: Alert | null): CommAction => ({ type: actionTypes.SET_ALERT, alert });
