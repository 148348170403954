// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// import { useLazyQuery } from '@apollo/client';
// import { Q } from '../helpers/gql';

import { State } from '../index';
// import * as actions from '../store/actions';

// import { DataType } from '../__types__/graphql-global-types';
// import { editCombo, editComboVariables } from '../helpers/__types__/editCombo';
import {
    EditComboName,
    SubPageName,
    // PanelConfig,
    Panel,
    PanelPtr,
    ChooserConfig,
    ChooserData,
    EditorConfig,
    EditorData,
    SubPage,
    EditCombo
} from '../types/ui/EditCombo';
import { PanelComponentType } from '../__types__/graphql-global-types';

const useEditComboState = (): {
    getEditCombo: (editComboName: EditComboName) => EditCombo | undefined;
    getSubPages: (editComboName: EditComboName) => SubPage[] | null;
    getCurrSubPageName: (editComboName: EditComboName) => SubPageName | null;
    getSubPage: (editComboName: EditComboName, subPageName: SubPageName) => SubPage | null;
    getPanel: (panelPtr: PanelPtr) => Panel | null;
    // getDataType: (panelPtr: PanelPtr) => DataType | null;
    getChooserConfig: (panelPtr: PanelPtr) => ChooserConfig | null;
    getChooserData: (panelPtr: PanelPtr) => ChooserData | null;
    getEditorConfig: (panelPtr: PanelPtr) => EditorConfig | null;
    getEditorData: (panelPtr: PanelPtr) => EditorData | null;
} => {
    // const dispatch = useDispatch();

    // const [execGetEditCombo] = useLazyQuery<editCombo, editComboVariables>(Q.GET_EDIT_COMBO, {
    //     onCompleted: res => {
    //         console.log('Got editCombo:', res.editCombo);
    //         dispatch(actions.setEditCombo(res.editCombo as EditCombo));
    //     }
    // });

    const editComboState = useSelector((state: State) => state.editCombos);

    // const getEditCombo = (editComboName: EditComboName) => editComboState[editComboName] ?? null;
    // const getEditCombo = (editComboName: EditComboName) => editComboState.find(e => e.name === editComboName);
    const getEditCombo = (editComboName: EditComboName) =>
        // const eC = editComboState.find(e => e.name === editComboName);
        // if (!eC) execGetEditCombo({ variables: { name: editComboName } });
        // return eC;
        editComboState.find(e => e.name === editComboName);

    const getSubPages = (editComboName: EditComboName) => {
        const eC = getEditCombo(editComboName);
        return eC?.subPages ?? null;
    };

    const getCurrSubPageName = (editComboName: EditComboName) => {
        const eC = getEditCombo(editComboName);
        // const currSubPageNum = eC?.currSubPageNum ?? 0;
        // const currSubPage = eC?.subPages ? eC.subPages[currSubPageNum] ?? null : null;
        // return (currSubPage?.name as SubPageName) ?? null;
        return (eC?.status?.currSubPageName as SubPageName) ?? null;
    };

    const getSubPage = (editComboName: EditComboName, subPageName: SubPageName) => {
        const eC = getEditCombo(editComboName);
        // return eC?.subPages ? eC.subPages.find(s => s.name === subPageName) ?? null : null;
        return eC?.subPages.find(s => s.name === subPageName) ?? null;
    };

    const getPanel = (panelPtr: PanelPtr) => {
        const subPage = getSubPage(panelPtr.editComboName, panelPtr.subPageName);
        // console.log('getPanel - panelPtr, subPage:', panelPtr, subPage);
        return (
            subPage?.panels.find(
                p =>
                    p.panelGrpName === (panelPtr.panelGrpName ?? null) &&
                    p.attrs.componentType === panelPtr.componentType
            ) ?? null
        );
    };

    // const getDataType = (panelPtr: PanelPtr) => {
    //     const panel = getPanel(panelPtr);
    //     return panel.config.componentType === 'chooser' ? panel.config.dataType : null;
    // };

    const getChooserConfig = (panelPtr: PanelPtr) => {
        // const subPage = getSubPage(editComboName, subPageName);
        // return subPage?.chooser?.config ?? null;
        const panel = getPanel({ ...panelPtr, componentType: PanelComponentType.chooser });
        return panel?.attrs.componentType === PanelComponentType.chooser ? panel.attrs.config : null;
    };

    const getChooserData = (panelPtr: PanelPtr) => {
        // const subPage = getSubPage(editComboName, subPageName);
        // const chooserPanel = subPage?.chooser?.panelType === 'chooser' ? subPage?.chooser : undefined;
        // return chooserPanel?.panel ?? null;
        // return subPage?.chooser?.data ?? null;
        const panel = getPanel({ ...panelPtr, componentType: PanelComponentType.chooser });
        return panel?.attrs.componentType === PanelComponentType.chooser ? panel.attrs.data : null;
    };

    const getEditorConfig = (panelPtr: PanelPtr) => {
        // const subPage = getSubPage(editComboName, subPageName);
        // const editorPanel = subPage?.editor?.panelType === 'editor' ? subPage?.editor : undefined;
        // const editor = editorPanel?.panel;
        // const editor = subPage?.editorConfig ?? null;
        // return editor?.config ?? null;
        // return subPage?.editor?.config ?? null;
        const panel = getPanel({ ...panelPtr, componentType: PanelComponentType.editor });
        return panel?.attrs.componentType === PanelComponentType.editor ? panel.attrs.config : null;
    };

    const getEditorData = (panelPtr: PanelPtr) => {
        // const subPage = getSubPage(editComboName, subPageName);
        // const editorPanel = subPage?.editor?.panelType === 'editor' ? subPage?.editor : undefined;
        // const editor = editorPanel?.panel;
        // const editor = subPage?.editor ?? null;
        // return { db: editor?.data?.db ?? null, local: editor?.data?.local ?? null };
        const panel = getPanel({ ...panelPtr, componentType: PanelComponentType.editor });
        // console.log('*** Reading from panelPtr, panel:', panelPtr, panel);
        return panel?.attrs.componentType === PanelComponentType.editor
            ? { db: panel.attrs.data?.db ?? null, local: panel.attrs.data?.local ?? null }
            : null;
    };

    return {
        getEditCombo,
        getSubPages,
        getCurrSubPageName,
        getSubPage,
        getPanel,
        // getDataType,
        getChooserConfig,
        getChooserData,
        getEditorConfig,
        getEditorData
    };
};

export default useEditComboState;
