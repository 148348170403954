import React, { useState, useEffect, useMemo, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import _ from 'lodash';

import { useMutation, ApolloError } from '@apollo/client';
import { M } from '../helpers/gql';
import { cloneDeepNonNull } from '../utils/utils';
// import { pages } from '../contexts/pageData';

import { State } from '../index';
import * as actions from '../store/actions';
import { saveObj, saveObjVariables } from '../helpers/__types__/saveObj';

import { PanelComponentType } from '../__types__/graphql-global-types';
// import { EditComboName, PanelPtr } from '../types/ui/EditCombo';
import { EditComboName } from '../types/ui/EditCombo';

import useEditComboState from '../hooks/use-editCombo-state';

import ConfirmPopup from '../ui-core/ui/ConfirmPopup/ConfirmPopup';

const DataProtector = createContext({});

export const DataProtectorProvider: React.FC = props => {
    const dispatch = useDispatch();

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ApolloError | undefined>();

    const userMode = useSelector((state: State) => state.auth.userMode);

    const pagesState = useSelector((state: State) => state.pages);
    const currPageNum = pagesState.currPageNum[userMode];
    const currPage = pagesState.pages[currPageNum];
    const currPageName = currPage?.name ?? 'noCurrPage';

    // const editComboState = useSelector((state: State) => state.editCombo);
    // const editComboId = currPage.type === 'editCombo' ? (currPageName as EditComboId) : undefined;

    // const { getCurrSubPageId, getSubPage, getDataType, getEditorData } = useEditComboState();
    // const { getCurrSubPageName, getSubPage, getPanel, getEditorData } = useEditComboState();
    const { getCurrSubPageName, getPanel, getEditorData } = useEditComboState();

    // const currSubPageId =
    //     currPage.type === 'editCombo' ? editComboState[currPageName as EditComboId]?.currSubPageId : undefined;
    const currEditComboName = currPage?.type === 'editCombo' ? (currPageName as EditComboName) : undefined;
    const currSubPageName = currEditComboName ? getCurrSubPageName(currEditComboName) : undefined;
    // const currSubPage =
    //     currEditComboName && currSubPageName ? getSubPage(currEditComboName, currSubPageName) : undefined;
    // const dataType = currEditComboId && currSubPageName ? getDataType(currEditComboId, currSubPageName) : undefined;
    // const currData = currEditComboId && currSubPageName ? getEditorData(currEditComboId, currSubPageName).local : undefined;

    const panelPtr =
        currEditComboName && currSubPageName
            ? {
                  editComboName: currEditComboName,
                  subPageName: currSubPageName,
                  componentType: PanelComponentType.editor
              }
            : null;

    const panel = panelPtr ? getPanel(panelPtr) : null;
    const dataType = panel?.attrs.componentType === 'editor' ? panel.attrs.dataType : null;
    const currData = panelPtr ? getEditorData(panelPtr)?.local : null;

    const { trigger } = pagesState;

    const [execSaveObj] = useMutation<saveObj, saveObjVariables>(M.SAVE_OBJ, {
        onCompleted: res => {
            const data = cloneDeepNonNull(res.saveObj);
            console.log('###> data:', data);
            // if (data.personality) data.personality = JSON.parse(data.personality);
            if (panelPtr) {
                // dispatch(actions.setEditorData(currEditComboId, currSubPageId, 'db', data));
                // dispatch(actions.setEditorData(currEditComboId, currSubPageId, 'local', data));
                // dispatch(actions.setChooserSelectedId(currEditComboId, currSubPageId, data.id));
                // dispatch(actions.setSubPageStatus(currEditComboId, currSubPageId, { showErrors: false }));
                // if (!currSubPage?.status?.chooserSelectedId)
                dispatch(
                    actions.setSubPageStatus(panelPtr, {
                        changed: false,
                        chooserSelectedId: data.id,
                        reloadChooser: true
                    })
                );
            } else {
                console.log(
                    'DataProtector.execSaveObj - bad currEditComboId, currSubPageId:',
                    currEditComboName,
                    currSubPageName
                );
            }
            console.log('Setting status to proceed');
            dispatch(actions.updateTriggerStatus('proceed'));
            setProcessing(false);
        },
        onError: (e: ApolloError) => {
            setError(e);
            dispatch(actions.updateTriggerStatus('error'));
            setProcessing(false);
        }
    });

    console.log(
        'trigger?.status, currPageName, panelPtr, panel, dataType, currData:',
        trigger?.status,
        currPageName,
        panelPtr,
        panel,
        dataType,
        currData
    );

    useEffect(() => {
        if (trigger?.status === 'execSave' && dataType && currData && !processing) {
            console.log('DataProtector.useEffect - save - trigger, dataType, currData:', trigger, dataType, currData);

            // const { subPages } = editComboState[currPageName as EditComboId];
            // const subPage = currPage.subPages ? currPage.subPages[currSubPageId as SubPageId] : undefined;
            // const data = subPage.panels.editor.panel?.data.local;
            // const data = currEditComboId ? getEditorData(currEditComboId, currSubPageId).local : undefined;

            setError(undefined);
            setProcessing(true);

            // console.log('About to call save');
            // if (dataType) {
            execSaveObj({ variables: { dataType, data: JSON.stringify(cloneDeepNonNull(currData)) } });
            // } else {
            //     console.log('DataProtector - useEffect.execSaveObj - no dataType');
            // }
        }
    }, [trigger, dataType, currData, processing, getEditorData, execSaveObj]);

    return (
        <DataProtector.Provider value={useMemo(() => ({}), [])}>
            <>
                <ConfirmPopup
                    panelPtr={panelPtr}
                    loading={processing}
                    saveError={error}
                    clearSaveError={() => setError(undefined)}
                />
                {props.children}
            </>
        </DataProtector.Provider>
    );
};

export default DataProtector;
