import * as actionTypes from './actionTypes';

import { Versions, ScreenDims, ScrollAmt, ProcessName, MainAction } from '../reducers/main';

export const setUIVar = (path: string, val: string | number | boolean): MainAction => ({
    type: actionTypes.SET_UI_VAR,
    path,
    val
});

export const setNeedReadRMCookie = (needReadRMCookie: boolean): MainAction => ({
    type: actionTypes.SET_NEED_READ_RM_COOKIE,
    needReadRMCookie
});

export const setAreTempCredentials = (areTempCredentials: boolean): MainAction => ({
    type: actionTypes.SET_ARE_TEMP_CREDENTIALS,
    areTempCredentials
});

export const setVersions = (versions: Versions | null): MainAction => ({ type: actionTypes.SET_VERSIONS, versions });

export const setScreenDims = (screenDims: ScreenDims): MainAction => ({
    type: actionTypes.SET_SCREEN_DIMS,
    screenDims
});

export const setScrollAmt = (scrollAmt: ScrollAmt): MainAction => ({ type: actionTypes.SET_SCROLL_AMT, scrollAmt });

export const setInputFocused = (inputFocused: boolean): MainAction => ({
    type: actionTypes.SET_INPUT_FOCUSED,
    inputFocused
});

export const setComingSoon = (comingSoon: boolean): MainAction => ({ type: actionTypes.SET_COMING_SOON, comingSoon });

export const setInitializing = (initializing: boolean): MainAction => ({
    type: actionTypes.SET_INITIALIZING,
    initializing
});

export const setShowAdmin = (showAdmin: boolean): MainAction => ({ type: actionTypes.SET_SHOW_ADMIN, showAdmin });

export const setDebug = (debug: boolean): MainAction => ({ type: actionTypes.SET_DEBUG, debug });

export const addProcessDebugData = (processName: ProcessName, data: string): MainAction => ({
    type: actionTypes.ADD_PROCESS_DEBUG_DATA,
    processName,
    data
});

export const setShowProcessDebugData = (processName: ProcessName | null): MainAction => ({
    type: actionTypes.SET_SHOW_PROCESS_DEBUG_DATA,
    processName
});

export const setDemoMode = (demoMode: boolean): MainAction => ({ type: actionTypes.SET_DEMO_MODE, demoMode });

export const setPleaseWait = (target: string): MainAction => ({ type: actionTypes.SET_PLEASE_WAIT, target });

export const clearPleaseWait = (target: string): MainAction => ({ type: actionTypes.CLEAR_PLEASE_WAIT, target });
