import * as actionTypes from './actionTypes';

// import { SubPageId } from '../../types/ui/EditCombo';

import { UserMode } from '../reducers/auth';
import { Page, Trigger, TriggerStatus, PagesAction } from '../reducers/pages';

export const setPages = (pages: Page[]): PagesAction => ({ type: actionTypes.SET_PAGES, pages });

export const setCurrPageNum = (userMode: UserMode, pageNum: number): PagesAction => ({
    type: actionTypes.SET_CURR_PAGE_NUM,
    userMode,
    pageNum
});

// export const setCurrSubPage = (pageName: PageName, subPageId: SubPageId): PagesAction => ({
//     type: actionTypes.SET_CURR_SUBPAGE,
//     pageName,
//     subPageId
// });

export const setTrigger = (trigger: Trigger): PagesAction => ({ type: actionTypes.SET_TRIGGER, trigger });

export const updateTriggerStatus = (status: TriggerStatus): PagesAction => ({
    type: actionTypes.UPDATE_TRIGGER_STATUS,
    status
});

export const clearTrigger = (): PagesAction => ({ type: actionTypes.CLEAR_TRIGGER });
