import React from 'react';

type Props = {
    onClick?: () => void;
};

const Popup: React.FC<Props> = props => (
    <div className="popup" onClick={props.onClick ?? (() => ({}))}>
        <div className="popup__bg" />

        <div className="popup__contents">{props.children}</div>
    </div>
);

Popup.defaultProps = {
    onClick: undefined
};

export default Popup;
