// import React, { useState, useEffect, useCallback /*, useContext*/ } from 'react';
// import React, { useState, useEffect, useMemo } from 'react';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';

import { useMutation, ApolloError } from '@apollo/client';
import { M } from '../../../helpers/gql';
import { initObj, initObjVariables } from '../../../helpers/__types__/initObj';
import { dupeObj, dupeObjVariables } from '../../../helpers/__types__/dupeObj';
import { deleteObj, deleteObjVariables } from '../../../helpers/__types__/deleteObj';

// import { pages } from '../../../contexts/pageData';
// import { buildItemSummary } from '../../../utils/utils';

import { State } from '../../../index';
import * as actions from '../../../store/actions';

// import PagesContext from '../../../contexts/PagesContext';

import { EditComboName, SubPageName, PanelPtr } from '../../../types/ui/EditCombo';
// import { TriggerAction } from '../../../store/reducers/pages';

import useEditComboState from '../../../hooks/use-editCombo-state';

import Popup from '../Popup/Popup';
// import { PanelComponentType } from '../../../__types__/graphql-global-types';
// import { DataType } from '../../../__types__/graphql-global-types';

type Props = {
    panelPtr: PanelPtr | null;
    loading: boolean;
    saveError?: ApolloError;
    clearSaveError: () => void;
};

const ConfirmPopup: React.FC<Props> = ({ panelPtr, loading, saveError, clearSaveError }) => {
    const dispatch = useDispatch();

    // const Pages = useContext(PagesContext);

    // const state = useSelector((state: any) => state);
    const userMode = useSelector((state: State) => state.auth.userMode);

    const pages = useSelector((state: State) => state.pages.pages);
    const currPageNum = useSelector((state: State) => state.pages.currPageNum[userMode]);
    const currPage = pages[currPageNum];
    const currPageName = currPage?.name ?? 'noCurrPage';

    // const { getEditCombo, getCurrSubPageId, getSubPage, getChooserData, getEditorData } = useEditComboState();
    // const { getCurrSubPageId, getSubPage, getChooserData, getEditorData } = useEditComboState();
    const { getCurrSubPageName, getSubPage } = useEditComboState();

    // const editComboState = useSelector((state: State) => state.editCombo);
    const currEditComboName = currPage?.type === 'editCombo' ? (currPageName as EditComboName) : undefined;
    // const editCombo = currEditComboId ? getEditCombo(currEditComboId) : undefined;
    // const currSubPageName = editCombo?.currSubPageName;
    // const currSubPage = currEditComboId && currSubPageName ? getSubPage(currEditComboId, currSubPageName) : undefined;
    // const currSubPage = currSubPageName !== undefined ? editCombo?.subPages[currSubPageName] : null;
    // const currSubPage = currSubPageName !== undefined ? editCombo?.subPages[currSubPageName] : null;
    const currSubPageName = currEditComboName ? getCurrSubPageName(currEditComboName) : null;
    const currSubPage = currEditComboName && currSubPageName ? getSubPage(currEditComboName, currSubPageName) : null;
    // const dataType = currSubPage?.dataType;
    const chooserSelectedId = currSubPage?.status?.chooserSelectedId;
    const chooserMultiSelIds = currSubPage?.status?.chooserMultiSelIds;

    // const panelPtr: PanelPtr | null = useMemo(
    //     () =>
    //         currEditComboName && currSubPageName
    //             ? {
    //                   editComboName: currEditComboName,
    //                   subPageName: currSubPageName,
    //                   componentType: PanelComponentType.editor
    //               }
    //             : null,
    //     [currEditComboName, currSubPageName]
    // );

    // const panel = panelPtr ? getPanel(panelPtr) : null;
    // const dataType = panel?.attrs.componentType === 'editor' ? panel.attrs.dataType : null;
    // console.log('panelPtr, panel, dataType:', panelPtr, panel, dataType);

    // const currSubPageId = currEditComboId ? getCurrSubPageId(currEditComboId) : undefined;

    // const chooserData = currEditComboId && currSubPageId ? getChooserData(currEditComboId, currSubPageId) : undefined;
    // const editorData = currEditComboId && currSubPageId ? getEditorData(currEditComboId, currSubPageId) : undefined;
    // const localData = editorData ? editorData.local : undefined;

    // const chooser = currSubPage?.panels.chooser;
    // const chooserState = chooser?.panelType === 'chooser' ? chooser.panel : undefined;

    // const editor = currSubPage?.panels.editor;
    // const editorState = editor?.panelType === 'editor' ? editor?.panel : undefined;

    // const currSubPage = useSelector((state: State) => state.pages.currSubPage[currPageName]);

    // const chooserData = chooserState ? chooserState.data : [];

    const trigger = useSelector((state: State) => state.pages.trigger);
    const signedIn = useSelector((state: State) => state.auth.signedIn);

    // const editComboState = useSelector((state: State) => state.editCombo);
    // const chooserState =
    //     currPageName && currSubPage ? editComboState[currPageName].subPages[currSubPage].chooser : undefined;
    // const editorState =
    //     currPageName && currSubPage ? editComboState[currPageName].subPages[currSubPage].editor : undefined;
    // const localData = editorState ? editorState.data.local : undefined;
    // const changed = editorState ? editComboState[currPageName].subPages[currSubPage].status.changed : false;
    const changed = currSubPage ? currSubPage?.status?.changed : false;

    const [show, setShow] = useState(false);

    const itemSummary = 'No item summary';
    // let itemSummary: string | JSX.Element | (string | JSX.Element)[] = 'No item summary';
    // if (currSubPage) {
    //     if (trigger && trigger.action === 'delObjId' && chooserData) {
    //         itemSummary = 'Chooser data not found';
    //         const delObj = chooserData.find(obj => obj.id === trigger.objId);
    //         if (delObj) itemSummary = buildItemSummary(delObj, currSubPage.itemSummary);
    //     } else if (localData) {
    //         itemSummary = buildItemSummary(localData, currSubPage.itemSummary);
    //     }
    // } else {
    //     console.log('ConfirmPopup - deleting - no currSubPage');
    // }

    const [execInitObj, { loading: initLoading }] = useMutation<initObj, initObjVariables>(M.INIT_OBJ, {
        onCompleted: res => {
            const data = res.initObj;
            // console.log('trigger, data:', trigger, data);

            // const cPN = currPageName as EditComboId;
            // if (currEditComboName && currSubPageName && dataType && data) {
            if (panelPtr && data) {
                // const dataObj: DataObj = { dataType, data };
                dispatch(actions.setEditorData(panelPtr, 'db', data));
                dispatch(actions.setEditorData(panelPtr, 'local', data));
                // dispatch(actions.setEditComboData(cPN, currSubPageId, 'chooser', 'local', 'selectedItem', dataObj));
                dispatch(actions.setSubPageStatus(panelPtr, { showErrors: false }));
                // dispatch(actions.clearTrigger());
                // dispatch(actions.clearTrigger());
            } else {
                console.log('ConfirmPopup.execInitObj - bad panelPtr, data:', panelPtr, data);
            }
        },
        onError: err => errorHandler(err),
        fetchPolicy: 'network-only'
    });

    const [execDupeObj] = useMutation<dupeObj, dupeObjVariables>(M.DUPE_OBJ, {
        onCompleted: res => {
            console.log('Chooser dupeObj res:', res);

            const data = res.dupeObj;

            if (panelPtr && data) {
                dispatch(actions.setEditorData(panelPtr, 'db', data));
                dispatch(actions.setEditorData(panelPtr, 'local', data));
                dispatch(actions.setSubPageStatus(panelPtr, { changed: true, showErrors: false }));
                // dispatch(actions.clearTrigger());
            } else {
                console.log('ConfirmPopup.execDupeObj - bad panelPtr, data:', panelPtr, data);
            }
        },
        onError: e => {
            console.log('Chooser execDupeObj - error:', e);
        }
    });

    const [execDeleteObj, { loading: deleteLoading }] = useMutation<deleteObj, deleteObjVariables>(M.DELETE_OBJ, {
        onCompleted: () => {
            if (panelPtr && currSubPage) {
                dispatch(actions.setSubPageStatus(panelPtr, { reloadChooser: true }));
                // const { chooserSelectedId } = currSubPage;

                // const cPN = currPageName as EditComboId;
                if (trigger?.action === 'delObjId' && chooserSelectedId === trigger?.objId && trigger.dataType) {
                    // const nullObj = { dataType, data: null };
                    dispatch(actions.setEditorData(panelPtr, 'db', null));
                    dispatch(actions.setEditorData(panelPtr, 'local', null));
                } else {
                    console.log('ConfirmPopup.execDeleteObj - bad trigger?objID or dataType');
                }
                // dispatch(actions.clearTrigger());
            } else {
                console.log(
                    'ConfirmPopup.execDeleteObj - bad editComboId, currSubPageId, currSubPage:',
                    currEditComboName,
                    currSubPageName,
                    currSubPage
                );
            }
        },
        onError: err => errorHandler(err)
    });

    const errorHandler = (err: ApolloError) => {
        dispatch(actions.setError({ groupName: 'popup', error: err }));
        dispatch(actions.clearTrigger());
    };

    const config = {
        selPageNum: { confirmMsg: `You have made changes on the ${itemSummary} page. Do you want to save?` },
        selSubPageName: { confirmMsg: `You have made changes to ${itemSummary}. Do you want to save?` },
        selObjId: { confirmMsg: `You have made changes to ${itemSummary}. Do you want to save?` },
        multiSelObjId: { confirmMsg: '' },
        revert: { confirmMsg: `You have made changes to ${itemSummary}. Do you want to save?` },
        initObj: { confirmMsg: `You have made changes to ${itemSummary}. Do you want to save?` },
        dupeObj: { confirmMsg: `You have made changes to ${itemSummary}. Do you want to save?` },
        saveObj: { confirmMsg: '' },
        delObjId: { confirmMsg: `Do you want to delete ${itemSummary}?` }
    };

    // const getContents = useCallback(
    //     (action: TriggerAction) => {
    //         switch (action) {
    //             case 'initObj':
    //                 return {
    //                     msg: <span>You have made changes to {itemSummary}. Do you want to save?</span>,
    //                     confirm: () => dispatch(actions.updateTriggerStatus('execSave')),
    //                     proceed: () => {
    //                         console.log('About to call initObj');
    //                         if (trigger?.dataType) {
    //                             execInitObj({ variables: { dataType: trigger?.dataType } });
    //                         } else {
    //                             console.log('ConfirmPopup.getContents.initObj - bad trigger:', trigger);
    //                         }
    //                     }
    //                 };

    //             case 'delObj':
    //                 return {
    //                     msg: <span>Do you want to delete {itemSummary}?</span>,
    //                     confirm: () => {
    //                         if (trigger?.dataType && trigger?.objId) {
    //                             execDeleteObj({ variables: { dataType: trigger?.dataType, id: trigger?.objId } });
    //                         } else {
    //                             console.log('ConfirmPopup.getContents.delObj - bad trigger:', trigger);
    //                         }
    //                     }
    //                     // proceed: () => ({})
    //                 };

    //             case 'selObj':
    //                 return {
    //                     msg: <span>You have made changes to {itemSummary}. Do you want to save?</span>,
    //                     confirm: () => dispatch(actions.updateTriggerStatus('execSave')),
    //                     proceed: () => {
    //                         console.log(
    //                             'Handling selObj - currPage, currSubPage, trigger.objId:',
    //                             currPageName,
    //                             currSubPage,
    //                             trigger?.objId
    //                         );
    //                         // if (currSubPageName && trigger?.objId) {
    //                         //     dispatch(
    //                         //         actions.setSelectedItemIds(currPageName as EditComboId, currSubPageName, [
    //                         //             trigger?.objId
    //                         //         ])
    //                         //     );
    //                         // } else {
    //                         //     console.log('ConfirmPopup.getContents.selObj - bad currSubPageId or trigger:', trigger);
    //                         // }
    //                     }
    //                 };

    //             case 'selSubPageId':
    //                 return {
    //                     msg: <span>You have made changes to {itemSummary}. Do you want to save?</span>,
    //                     confirm: () => dispatch(actions.updateTriggerStatus('execSave')),
    //                     proceed: () => {
    //                         if (currSubPageName) {
    //                             if (changed)
    //                                 dispatch(actions.revertEditorData(currPageName as EditComboId, currSubPageName));
    //                         } else {
    //                             console.log('ConfirmPopup.getContents.selSubpage - no currSubPageId');
    //                         }

    //                         if (trigger?.subPageId) {
    //                             const subPageNum = editCombo?.subPages?.findIndex(s => s.name === trigger.subPageId);
    //                             if (subPageNum !== undefined)
    //                                 dispatch(actions.setCurrSubPageNum(currPageName as EditComboId, subPageNum));
    //                         } else {
    //                             console.log('ConfirmPopup.getContents.selSubPage error, trigger:', trigger);
    //                         }
    //                     }
    //                 };

    //             case 'selPage':
    //                 return {
    //                     msg: <span>You have made changes on the {itemSummary} page. Do you want to save?</span>,
    //                     confirm: () => dispatch(actions.updateTriggerStatus('execSave')),
    //                     proceed: () => {
    //                         if (changed) {
    //                             if (currSubPageName) {
    //                                 dispatch(actions.revertEditorData(currPageName as EditComboId, currSubPageName));
    //                                 dispatch(actions.setStatus(currPageName as EditComboId, { changed: false }));
    //                             } else {
    //                                 console.log('ConfirmPopup.getContents.selPage - no currSubPage');
    //                             }
    //                         }
    //                         if (trigger?.pageNum !== undefined) {
    //                             dispatch(actions.setCurrPageNum(userMode, trigger.pageNum));
    //                         } else {
    //                             console.log('ConfirmPopup, selPage error, trigger:', trigger);
    //                         }
    //                     }
    //                 };

    //             case 'saveObj':
    //                 return {
    //                     msg: null,
    //                     confirm: () => dispatch(actions.updateTriggerStatus('execSave')),
    //                     proceed: () => {
    //                         console.log('about to proceed after save');
    //                     }
    //                 };

    //             default:
    //                 console.log('ConfirmPopup.getContents - bad action:', action);
    //                 return {
    //                     msg: null,
    //                     confirm: () => ({}),
    //                     proceed: () => ({})
    //                 };
    //         }
    //     },
    //     [
    //         currPageName,
    //         currSubPageName,
    //         editCombo,
    //         currSubPage,
    //         itemSummary,
    //         trigger,
    //         changed,
    //         userMode,
    //         execInitObj,
    //         execDeleteObj,
    //         dispatch
    //     ]
    // );

    useEffect(() => {
        console.log('ConfirmPopup - useEffect trigger, signedIn:', trigger, signedIn);

        if (trigger && signedIn) {
            // let newSelectedIds = _.cloneDeep(currSubPage?.chooserMultiSelIds ?? []);
            let newSelectedIds = _.cloneDeep(chooserMultiSelIds ?? []);

            // console.log('trigger.action, trigger.status:', trigger.action, trigger.status);

            // console.log('trigger.status:', trigger.status);
            // const contents = getContents(trigger.action);
            // const contents = messages[trigger.action];

            switch (trigger.status) {
                // case 'new':
                //     if (trigger.action === 'delObj') {
                //         setShow(true);
                //     } else if (trigger.action === 'saveObj') {
                //         // contents.confirm();
                //         dispatch(actions.updateTriggerStatus('execSave'));
                //     } else if (changed) {
                //         setShow(true);
                //     } else {
                //         // if (contents.proceed) contents.proceed();
                //         console.log('Need to proceed!');
                //         dispatch(actions.clearTrigger());
                //     }
                //     break;

                // case 'proceed':
                //     // if (contents.proceed) contents.proceed();
                //     console.log('Need to proceed!');
                //     dispatch(actions.clearTrigger());
                //     break;

                case 'offerSaveIfChanged':
                    if (changed) {
                        setShow(true);
                    } else {
                        dispatch(actions.updateTriggerStatus('proceed'));
                    }
                    break;

                case 'confirm':
                    setShow(true);
                    break;

                case 'proceed':
                    switch (trigger.action) {
                        case 'selPageNum':
                            dispatch(actions.setCurrPageNum(userMode, trigger.pageNum));
                            dispatch(actions.clearTrigger());
                            break;

                        case 'selSubPageName':
                            if (currEditComboName) {
                                dispatch(
                                    actions.setCurrSubPageName(currEditComboName, trigger.subPageName as SubPageName)
                                );
                                dispatch(actions.clearTrigger());
                            } else {
                                console.log('ConfirmPopup - selSubPageName - bad currEditComboId:', currEditComboName);
                            }
                            break;

                        case 'selObjId':
                            if (panelPtr) {
                                // dispatch(actions.setChooserSelectedId(currEditComboId, currSubPageId, trigger.objId));
                                dispatch(actions.setSubPageStatus(panelPtr, { chooserSelectedId: trigger.objId }));
                                dispatch(actions.clearTrigger());
                            } else {
                                console.log('ConfirmPopup - selObjId - error:', panelPtr);
                            }
                            break;

                        case 'multiSelObjId':
                            console.log('Looking for in:', trigger.objId, newSelectedIds);
                            if (!newSelectedIds.includes(trigger.objId)) {
                                newSelectedIds.push(trigger.objId);
                            } else {
                                newSelectedIds = newSelectedIds.filter(id => id !== trigger.objId);
                            }

                            if (panelPtr) {
                                // dispatch(actions.setChooserMultiSelIds(currEditComboId, currSubPageId, newSelectedIds));
                                dispatch(actions.setSubPageStatus(panelPtr, { chooserMultiSelIds: newSelectedIds }));
                                dispatch(actions.clearTrigger());
                            } else {
                                console.log(
                                    'ConfirmPopup - multiSelObjId - error:',
                                    currEditComboName,
                                    currSubPageName
                                );
                            }
                            // console.log('newSelectedIds, trigger.objId:', newSelectedIds, trigger.objId);
                            break;

                        case 'revert':
                            if (panelPtr) {
                                dispatch(actions.revertEditorData(panelPtr));
                                // dispatch(actions.setStatus(currEditComboId, { changed: false }));
                                dispatch(actions.clearTrigger());
                            } else {
                                console.log('ConfirmPopup - revert - error vals:', panelPtr);
                            }
                            break;

                        case 'initObj':
                            dispatch(actions.clearTrigger());
                            execInitObj({ variables: { dataType: trigger.dataType } });
                            break;

                        case 'dupeObj':
                            dispatch(actions.clearTrigger());
                            execDupeObj({ variables: { dataType: trigger.dataType, id: trigger.objId } });
                            break;

                        case 'saveObj':
                            dispatch(actions.clearTrigger());
                            break;

                        case 'delObjId':
                            dispatch(actions.clearTrigger());
                            execDeleteObj({ variables: { dataType: trigger.dataType, id: trigger.objId } });
                            break;

                        default:
                            console.log('ConfirmPopup - useEffect - bad proceed trigger.action');
                            break;
                    }
                    break;

                case 'error':
                    setShow(true);
                    break;

                default:
                    console.log('ConfirmPopup - useEffect - unhandled trigger.status:', trigger.status);
            }
        } else if (show) {
            console.log('Trigger problem - trigger, signedIn:', trigger, signedIn);
            setShow(false);
        }
        // }, [trigger, signedIn, changed, show, getContents, dispatch]);
    }, [
        trigger,
        signedIn,
        currEditComboName,
        currSubPageName,
        panelPtr,
        // currSubPage?.chooserMultiSelIds,
        chooserMultiSelIds,
        userMode,
        changed,
        show,
        execInitObj,
        execDupeObj,
        execDeleteObj,
        dispatch
    ]);

    // let contents: any = {};
    if (trigger && show) {
        // const contents = getContents(trigger.action);
        // const contents = <span>{config[trigger.action].confirmMsg}</span>;

        return (
            <Popup>
                <div className={`confirmPopup confirmPopup--${trigger.action}`}>
                    <div>Confirm Popup</div>
                    <div className="confirmPopup__msg">{config[trigger.action].confirmMsg}</div>
                    {!saveError ? (
                        <div>
                            <div
                                className="confirmPopup__btn confirmPopup__btn--yes"
                                onClick={() => {
                                    switch (trigger.action) {
                                        case 'delObjId':
                                            dispatch(actions.updateTriggerStatus('proceed'));
                                            break;

                                        default:
                                            dispatch(actions.updateTriggerStatus('execSave'));
                                            break;
                                    }
                                    // contents.confirm();
                                }}
                            >
                                Yes
                            </div>

                            <div
                                className="confirmPopup__btn confirmPopup__btn--no"
                                onClick={() => {
                                    dispatch(actions.updateTriggerStatus('proceed'));
                                }}
                            >
                                No
                            </div>

                            {trigger.action !== 'delObjId' ? (
                                <div
                                    className="confirmPopup__btn confirmPopup__btn--cancel"
                                    onClick={() => dispatch(actions.clearTrigger())}
                                >
                                    Cancel
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {loading || initLoading || deleteLoading ? <div>LOADING</div> : null}

                    {saveError ? (
                        <div>
                            <div>Error: {saveError.message}</div>
                            <div
                                className="confirmPopup__btn confirmPopup__btn--ok"
                                onClick={() => {
                                    dispatch(actions.clearTrigger());
                                    clearSaveError();
                                }}
                            >
                                Ok
                            </div>
                        </div>
                    ) : null}
                </div>
            </Popup>
        );
    }

    return null;
};

ConfirmPopup.defaultProps = {
    saveError: undefined
};

export default ConfirmPopup;
