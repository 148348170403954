import React, { useState, Suspense, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';

import { useLazyQuery } from '@apollo/client';
import { Q } from './helpers/gql';

import { State } from './index';
import * as actions from './store/actions';

import useAccessToken from './hooks/use-access-token';
import useSockets from './hooks/use-sockets';

import Alert from './ui-core/ui/Alert/Alert';
import ForcePortrait from './ui-core/ui/ForcePortrait/ForcePortrait';

const PageRouter = React.lazy(() => import('./ui-core/PageRouter/PageRouter'));

const App: React.FC = () => {
    const dispatch = useDispatch();

    const { startCookieReadLoop } = useAccessToken();
    const { initSockets } = useSockets();

    const screenDims = useSelector((state: State) => state.main.screenDims);

    const [ping] = useLazyQuery(Q.PING, {
        onCompleted: res => {
            console.log('ping:', res.ping);
        },
        onError: err => {
            console.log('ping, err:', err);
        }
    });

    const [init, setInit] = useState(true);
    const [phoneLandscape, setPhoneLandscape] = useState(false);

    const resizedHandler = useCallback(() => {
        dispatch(
            actions.setScreenDims({
                width: window.innerWidth,
                height: window.innerHeight
            })
        );
    }, [dispatch]);

    const scrollHandler = useCallback(() => {
        dispatch(actions.setScrollAmt({ x: window.pageXOffset, y: window.pageYOffset }));
    }, [dispatch]);

    useEffect(() => {
        if (init) {
            console.log('*** App.useEffect - initializing ***');
            initSockets();
            startCookieReadLoop();
            setInit(false);
        }
    }, [init, initSockets, startCookieReadLoop, dispatch]);

    useEffect(() => {
        console.log('*** App.useEffect - setting up event listeners');

        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('resize', resizedHandler);
        // window.addEventListener('orientationchange', resizedHandler);

        return () => {
            console.log('*** App.useEffect - clearing event listeners');

            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('resize', resizedHandler);
            // window.removeEventListener('orientationchange', resizedHandler);
        };
    }, [resizedHandler, scrollHandler]);

    useEffect(() => {
        if (isMobileOnly) {
            if (screenDims.width > screenDims.height) {
                setPhoneLandscape(true);
                console.log('Now landscape!');
            } else {
                setPhoneLandscape(false);
                console.log('Now portrait!');
            }
        }
    }, [screenDims]);

    useEffect(() => {
        console.log('About to call ping');
        ping();
    }, [ping]);

    const fallback = (
        <div className="app__fallback">
            <div style={{ marginBottom: '20%', fontSize: '2rem', color: '#444' }}>Loading PageRouter...</div>
        </div>
    );

    return (
        <Suspense fallback={fallback}>
            <div className="app">
                {phoneLandscape ? (
                    <ForcePortrait />
                ) : (
                    <>
                        <PageRouter />
                        <Alert />
                    </>
                )}
            </div>
        </Suspense>
    );
};

export default App;
