// Main
export const SET_UI_VAR = 'SET_UI_VAR';
export const SET_NEED_READ_RM_COOKIE = 'SET_NEED_READ_RM_COOKIE';
export const SET_ARE_TEMP_CREDENTIALS = 'SET_ARE_TEMP_CREDENTIALS';
export const SET_VERSIONS = 'SET_VERSIONS';
export const SET_SCREEN_DIMS = 'SET_SCREEN_DIMS';
export const SET_SCROLL_AMT = 'SET_SCROLL_AMT';
export const SET_INPUT_FOCUSED = 'SET_INPUT_FOCUSED';
export const SET_COMING_SOON = 'SET_COMING_SOON';
export const SET_INITIALIZING = 'SET_INITIALIZING';
export const SET_SHOW_ADMIN = 'SET_SHOW_ADMIN';
export const SET_DEBUG = 'SET_DEBUG';
export const ADD_PROCESS_DEBUG_DATA = 'ADD_PROCESS_DEBUG_DATA';
export const SET_SHOW_PROCESS_DEBUG_DATA = 'SET_SHOW_PROCESS_DEBUG_DATA';
export const SET_DEMO_MODE = 'SET_DEMO_MODE';
export const SET_PLEASE_WAIT = 'SET_PLEASE_WAIT';
export const CLEAR_PLEASE_WAIT = 'CLEAR_PLEASE_WAIT';

// Nav
export const SET_NAV_TRIGGER = 'SET_NAV_TRIGGER';
export const CLEAR_NAV_TRIGGER = 'CLEAR_NAV_TRIGGER';
export const SET_SEL_PAGE = 'SET_SEL_PAGE';

// Pages
export const SET_PAGES = 'SET_PAGES';
export const SET_CURR_PAGE_NUM = 'SET_CURR_PAGE_NUM';
// export const SET_CURR_SUBPAGE = 'SET_CURR_SUBPAGE';
export const SET_TRIGGER = 'SET_TRIGGER';
export const UPDATE_TRIGGER_STATUS = 'UPDATE_TRIGGER_STATUS';
export const CLEAR_TRIGGER = 'CLEAR_TRIGGER';

// Errors
export const SET_ERROR = 'SET_ERROR';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Auth
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_AT_DATA = 'SET_AT_DATA';
// export const SET_AT_DISAPPEARED = 'SET_AT_DISAPPEARED';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';

// Comm
export const SET_SOCKET_CONNECTING = 'SET_SOCKET_CONNECTING';
export const SET_SOCKET = 'SET_SOCKET';
export const SET_SOCKET_EVENT_DATA = 'SET_SOCKET_EVENT_DATA';
export const SET_ALERT = 'SET_ALERT';

// EditCombo
export const SET_EDIT_COMBO = 'SET_EDIT_COMBO';
export const SET_CURR_SUBPAGE_NAME = 'SET_CURR_SUBPAGE_NAME';
// export const SET_CHOOSER_SELECTED_ID = 'SET_CHOOSER_SELECTED_ID';
// export const SET_CHOOSER_MULTI_SEL_IDS = 'SET_CHOOSER_MULTI_SEL_IDS';
export const SET_STATUS = 'SET_STATUS';
export const SET_SUBPAGE_STATUS = 'SET_SUBPAGE_STATUS';
export const SET_CHOOSER_CONFIG = 'SET_CHOOSER_CONFIG';
export const SET_EDITOR_CONFIG = 'SET_EDITOR_CONFIG';
export const SET_CHOOSER_DATA = 'SET_CHOOSER_DATA';
export const SET_EDITOR_DATA = 'SET_EDITOR_DATA';
export const UPDATE_EDITOR_DATA = 'UPDATE_EDITOR_DATA';
// export const SET_EDIT_COMBO_DATA = 'SET_EDIT_COMBO_DATA';
export const SET_PERSONALITY_FLDS = 'SET_PERSONALITY_FLDS';

// export const TOGGLE_CHOOSER_SELECT = 'TOGGLE_CHOOSER_SELECT';

export const ADD_ARRAY_ITEM = 'ADD_ARRAY_ITEM';
export const DELETE_ARRAY_ITEM = 'DELETE_ARRAY_ITEM';
export const REVERT_EDITOR_DATA = 'REVERT_EDITOR_DATA';

// BigScroll
export const SET_BIGSCROLL_GENDER = 'SET_BIGSCROLL_GENDER';
export const SET_BIGSCROLL_NAV_HEIGHT = 'SET_BIGSCROLL_NAV_HEIGHT';
export const SET_BIGSCROLL_HEIGHT = 'SET_BIGSCROLL_HEIGHT';
export const SET_BIGSCROLL_AMT = 'SET_BIGSCROLL_AMT';
export const JUMP_TO_SECTION = 'JUMP_TO_SECTION';

// Survey
export const SET_SURVEY_LOAD_ID = 'SET_SURVEY_LOAD_ID';
export const SET_SURVEY_CONFIG = 'SET_SURVEY_CONFIG';
export const UPDATE_SURVEY_FROM_EDITOR = 'UPDATE_SURVEY_FROM_EDITOR';
export const SET_SURVEY_DATA = 'SET_SURVEY_DATA';
export const SET_SURVEY_ERROR = 'SET_SURVEY_ERROR';
export const CLEAR_SURVEY_ERROR = 'CLEAR_SURVEY_ERROR';
export const SET_SHOW_PAGE_ERRORS = 'SET_SHOW_PAGE_ERRORS';

// User
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
