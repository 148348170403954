import { Reducer } from 'redux';

import _ from 'lodash';

// import { SET_NAV_TRIGGER, CLEAR_NAV_TRIGGER, SET_SEL_PAGE } from '../actions/actionTypes';
import { SET_SEL_PAGE } from '../actions/actionTypes';

export type NavType = 'landing' | 'client';

export type NavState = {
    // navTrigger: Partial<Record<NavType, string>>;
    selPage: Partial<Record<NavType, string>>;
};

export type NavAction =
    // | { type: 'SET_NAV_TRIGGER'; navType: NavType; val: string }
    // | { type: 'CLEAR_NAV_TRIGGER'; navType: NavType }
    { type: 'SET_SEL_PAGE'; navType: NavType; val: string | null };

const initialState = {
    // navTrigger: {},
    selPage: {}
};

const reducer: Reducer<NavState, NavAction> = (state = initialState, action) => {
    // let navTrigger: any;
    const selPage = _.cloneDeep(state.selPage);

    switch (action.type) {
        // case SET_NAV_TRIGGER:
        //     navTrigger = _.cloneDeep(state.navTrigger ?? {});

        //     navTrigger[action.navType] = action.val;
        //     return { ...state, navTrigger };

        // case CLEAR_NAV_TRIGGER:
        //     navTrigger = _.cloneDeep(state.navTrigger);

        //     delete navTrigger[action.navType];
        //     return { ...state, navTrigger };

        case SET_SEL_PAGE:
            if (action.val) {
                selPage[action.navType] = action.val;
            } else {
                delete selPage[action.navType];
            }
            return { ...state, selPage };

        default:
            return state;
    }
};

export default reducer;
