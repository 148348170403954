// import { InMemoryCache /*, Reference, makeVar*/ } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';

const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                getChooser: {
                    merge(existing, incoming) {
                        return { ...existing, ...incoming };
                    }
                }
            }
            // fields: {
            //     isLoggedIn: {
            //         read() {
            //             return isLoggedInVar();
            //         }
            //     },
            //     cartItems: {
            //         read() {
            //             return cartItemsVar();
            //         }
            //     },
            //     launches: {
            //         keyArgs: false,
            //         merge(existing, incoming) {
            //             let launches: Reference[] = [];
            //             if (existing && existing.launches) launches = launches.concat(existing.launches);
            //             if (incoming && incoming.launches) launches = launches.concat(incoming.launches);
            //             return { ...incoming, launches };
            //         }
            //     }
            // }
        }
    }
});

export default cache;
// // Initializes to true if localStorage includes a 'token' key, false otherwise
// export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem('token'));

// // Initializes to an empty array
// export const cartItemsVar = makeVar<string[]>([]);
