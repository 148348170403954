import { Reducer } from 'redux';
import _ from 'lodash';

import {
    SET_EDIT_COMBO,
    SET_CURR_SUBPAGE_NAME,
    // SET_CHOOSER_SELECTED_ID,
    // SET_CHOOSER_MULTI_SEL_IDS,
    SET_STATUS,
    SET_SUBPAGE_STATUS,
    SET_CHOOSER_CONFIG,
    SET_EDITOR_CONFIG,
    SET_CHOOSER_DATA,
    SET_EDITOR_DATA,
    UPDATE_EDITOR_DATA,
    // SET_EDIT_COMBO_DATA,
    SET_PERSONALITY_FLDS,
    //
    // TOGGLE_CHOOSER_SELECT,
    //
    ADD_ARRAY_ITEM,
    DELETE_ARRAY_ITEM,
    REVERT_EDITOR_DATA
} from '../actions/actionTypes';

// import { DataType } from '../../__types__/graphql-global-types';
// import { SubPagePanel } from '../../__types__/graphql-global-types';
// import User from '../../models/user';
// import { SubPageName } from './pages';

import {
    // DataObj,
    EditComboName,
    SubPageName,
    PanelPtr,
    Panel,
    EditComboStatus,
    SubPageStatus,
    // PanelType,
    DataLocation,
    Fld,
    FldData,
    // ChooserData,
    // EditorData,
    ChooserConfig,
    ChooserData,
    EditorConfig,
    EditorData,
    // Panel,
    // Panels,
    SubPage,
    EditCombo
    // Chooser,
    // Editor
    // Chooser as ChooserType,
    // Editor as EditorType
} from '../../types/ui/EditCombo';
import { PanelComponentType } from '../../__types__/graphql-global-types';
// import { PanelComponentType } from '../../__types__/graphql-global-types';

// export type EditComboName = 'user' | 'matching' | 'meeting' | 'messaging' | 'onboarding' | 'venue' | 'job' | 'settings';
// export type EditComboName = 'user';
// export type SubPageName =
//     | 'edit'
//     | 'history'
//     | 'post'
//     | 'dm'
//     | 'broadcast'
//     | 'surveyItem'
//     | 'surveyPage'
//     | 'survey'
//     | 'inviteCode'
//     | 'initialize';
// export type PanelType = 'chooser' | 'editor' | 'display';
// export type PanelType = 'chooser' | 'editor';
// export type DataLocation = 'config' | 'db' | 'local';

export type ChooserSortOption = { name: string; label?: string };
// prettier-ignore
export type ChooserToggle = { name: string; label: string; val: boolean; isDefaultVal: boolean; groups?: number[]; trueDisablesGroups?: number[] };
export type ChooserToggleGroup = { label: string; toggles: ChooserToggle[] };

// export type Section = {
//     name: string;
//     label: string;
//     type?: 'personality';
//     showHeader: boolean;
//     open: boolean;
//     flds: Fld[];
// };

// // prettier-ignore
// export type FldType = 'input' | 'textarea' | 'number' | 'select' | 'radio' | 'checkbox' | 'multiSelect' | 'email' |
//                         'updatePassword' | 'link' | 'date' | 'time' | 'dateTime' | 'image' | 'geoPoint' | 'personality' | 'button';
export type ItemDetails = { el: 'div' | 'span' | null; class: string | null; contents: string }[];
// export type Fld = {
//     name: string;
//     label: string;
//     type?: FldType;
//     itemName?: string;
//     itemLabel?: string;
//     funcName?: 'invite' | 'preMail';
//     isArray?: boolean;
//     minArraySize?: number;
//     maxArraySize?: number;
//     dataSrc?: DataType;
//     itemSummary?: string[];
//     itemDetails?: ItemDetails;
//     flds?: Fld[];
//     vals?: { label: string; intVal: number; floatVal: number; strVal: string; boolVal: boolean }[];
//     defaultVal?: string;
//     required?: boolean;
//     showIf?: string;
//     hidden?: boolean;
//     readOnly?: boolean;
// };

// export type Status = {
//     changed?: boolean;
// };

// export type SubPageStatus = {
//     changed?: boolean;
//     reloadChooser?: boolean;
//     chooserItemSelected?: boolean;
//     showErrors?: boolean;
// };

// type ChooserConfig = {
//     filterPlaceholder: string;
//     filterVal: string;
// };

// type ChooserItem = {
//     sortOptions: any;
// };

// type EditorConfig = {
//     sections: {
//         name: string;
//         label: string;
//         type?: 'personality';
//         showHeader: boolean;
//         open: boolean;
//         flds: Fld[];
//     }[];
// };

// type Chooser = {
//     enableNew?: boolean;
//     enableDelete?: boolean;
//     config?: ChooserConfig;
//     db?: ChooserItem[];
//     local?: ChooserItem[];
// };

// type Editor = { version: string; config?: EditorConfig; db?: { data: User }; local?: { data: User } };

// type Display = { db?: { data: User }; local?: { data: User } };

// type SubPage = {
//     status?: SubPageStatus;
//     selectedIds?: string[];
//     // version: string;
//     label: string;
//     dataType: DataType;
//     itemSummary?: string[];
//     itemSummarySimple?: string;
//     chooser?: ChooserType | null;
//     editor?: EditorType | null;
//     // display?: Display;
// };

// export type EditComboState = {
//     [key in EditComboName]: {
//         status: Status;
//         selectedIds: string[];
//         currSubPageName?: SubPageName;
//         subPages: { [key: string]: SubPage };
//     };
// };

export type EditCombosState = EditCombo[];

// const emptySubPage = {
//     status: {},
//     chooser: { config: {}, db: {}, local: {} },
//     editor: { config: {}, db: {}, local: {} }
// };

const initialState: EditCombosState = [];

// const initialState = [
//     {
//         name: 'user',
//         status: {},
//         currSubPageName: 'edit' as SubPageName,
//         subPages: {
//             edit: {
//                 status: {},
//                 // chooserSelectedId: '',
//                 // selectedIds: [],
//                 label: 'test',
//                 dataType: 'User' as DataType,
//                 // chooser: { enableNew: true, enableDelete: true },
//                 // editor: { version: 'edit' }
//                 // chooser: null,
//                 // editor: null
//                 panels: {
//                     chooser: null
//                     // chooser: {
//                     //     // config: {
//                     //     //     // __typename: 'ChooserConfig',
//                     //     //     filterPlaceholder: null,
//                     //     //     filterVal: null,
//                     //     //     sortOptions: [],
//                     //     //     toggleGroups: [],
//                     //     //     currSort: null,
//                     //     //     pagination: null
//                     //     // },
//                     //     // config: null,
//                     //     // data: null
//                     // }
//                     // editor: {
//                     //     panelType: 'editor' as PanelType,
//                     //     panel: null
//                     // }
//                 }
//             }
//             // invite: {
//             //     status: {},
//             //     label: 'test',
//             //     dataType: 'User' as DataType,
//             //     chooser: { enableNew: true, enableDelete: true },
//             //     editor: { version: 'edit' }
//             // },
//             // approve: {
//             //     status: {},
//             //     label: 'test',
//             //     dataType: 'User' as DataType,
//             //     chooser: { enableNew: true, enableDelete: true },
//             //     editor: { version: 'edit' }
//             // },
//             // match: {
//             //     status: {},
//             //     label: 'test',
//             //     dataType: 'User' as DataType,
//             //     chooser: { enableNew: true, enableDelete: true },
//             //     editor: { version: 'edit' }
//             // },
//             // history: {
//             //     status: {},
//             //     label: 'Users',
//             //     dataType: 'User' as DataType,
//             //     chooser: { enableNew: true, enableDelete: true },
//             //     display: {}
//             // }
//         }
//     }
//     // matching: { subPages: {} },
//     // meeting: { subPages: {} },
//     // messaging: { subPages: {} },
//     // onboarding: { subPages: {} },
//     // venue: { subPages: {} },
//     // job: { subPages: {} },
//     // settings: { subPages: {} }
//     // matching: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         matchRule: _.cloneDeep(emptySubPage),
//     //         matchRuleSet: _.cloneDeep(emptySubPage)
//     //     }
//     // },
//     // meeting: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         edit: _.cloneDeep(emptySubPage)
//     //     }
//     // },
//     // messaging: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         emailTemplate: _.cloneDeep(emptySubPage),
//     //         post: _.cloneDeep(emptySubPage),
//     //         dm: {
//     //             status: {},
//     //             chooser: { config: {}, db: {}, local: {} }
//     //         },
//     //         broadcast: {
//     //             status: {},
//     //             chooser: { config: {}, db: {}, local: {} }
//     //         }
//     //     }
//     // },
//     // onboarding: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         surveyItem: _.cloneDeep(emptySubPage),
//     //         surveyPage: _.cloneDeep(emptySubPage),
//     //         survey: _.cloneDeep(emptySubPage)
//     //     }
//     // },
//     // venue: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         edit: _.cloneDeep(emptySubPage)
//     //     }
//     // },
//     // job: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         edit: _.cloneDeep(emptySubPage)
//     //     }
//     // },
//     // settings: {
//     //     status: {},
//     //     selectedIds: [],
//     //     subPages: {
//     //         system: _.cloneDeep(emptySubPage),
//     //         inviteCode: _.cloneDeep(emptySubPage),
//     //         infoBox: _.cloneDeep(emptySubPage),
//     //         initialize: {
//     //             status: {}
//     //         }
//     //     }
//     // }
// ];

// prettier-ignore
export type EditComboAction =
    | { type: 'SET_EDIT_COMBO'; editCombo: EditCombo }
    | { type: 'SET_CURR_SUBPAGE_NAME'; editComboName: EditComboName; subPageName: SubPageName }
    // | { type: 'SET_CHOOSER_SELECTED_ID'; editComboName: EditComboName; subPageName: SubPageName; itemId: string }
    // | { type: 'SET_CHOOSER_MULTI_SEL_IDS'; editComboName: EditComboName; subPageName: SubPageName; itemIds: string[] }
    | { type: 'SET_STATUS'; editComboName: EditComboName; statusObj: EditComboStatus }
    | { type: 'SET_SUBPAGE_STATUS'; panelPtr: PanelPtr; statusObj: Partial<SubPageStatus> }
    | { type: 'SET_CHOOSER_CONFIG'; panelPtr: PanelPtr; chooserConfig: ChooserConfig }
    | { type: 'SET_EDITOR_CONFIG'; panelPtr: PanelPtr; editorConfig: EditorConfig }
    | { type: 'SET_CHOOSER_DATA'; panelPtr: PanelPtr; chooserData: ChooserData | null }
    | { type: 'SET_EDITOR_DATA'; panelPtr: PanelPtr; location: DataLocation; editorData: EditorData['db'] | null }
    | { type: 'UPDATE_EDITOR_DATA'; panelPtr: PanelPtr; location: DataLocation; path: string; data: FldData }
    // | { type: 'SET_EDIT_COMBO_DATA'; editComboName: EditComboName; subPageName: SubPageName; panel: keyof SubPage['panels']; dataLocation: DataLocation; dataName: string; data: DataObj }
    | { type: 'SET_PERSONALITY_FLDS'; panelPtr: PanelPtr; flds: Fld[] }
    // | { type: 'SET_EDIT_COMBO_CHANGED'; editComboName: EditComboName; changed: boolean }
    | { type: 'TOGGLE_CHOOSER_SELECT'; editComboName: EditComboName; subPageName: SubPageName; data: string}
    | { type: 'ADD_ARRAY_ITEM'; panelPtr: PanelPtr; path: string; fld: Fld }
    | { type: 'DELETE_ARRAY_ITEM'; panelPtr: PanelPtr; path: string; fld: Fld }
    | { type: 'REVERT_EDITOR_DATA'; panelPtr: PanelPtr; path?: string };
// | { type: 'CLEAR_TRIGGER_CONFIRM' }

// const updatePanel = (
//     state: EditComboState,
//     editComboName: EditComboName,
//     subPageName: SubPageName,
//     panel: PanelType,
//     // dataLocation: DataLocation,
//     // panelData: Chooser | Editor | Display
//     panelData: Data
// ) => {
//     const newState = _.cloneDeep(state);

//     newState[editComboName].subPages[subPageName][panel] = {
//         ...newState[editComboName].subPages[subPageName][panel],
//         ..._.cloneDeep(panelData)
//     };

//     return newState;
// };

// const clean = (data: DataObj['data']) => {
//     console.log('### Before:', data);
//     const d = _.cloneDeep(data);

//     if (d && _.isPlainObject(d)) {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         const newObj: { [key: string]: any } = {};

//         Object.entries(d).forEach(([key, val]) => {
//             if (Array.isArray(val)) {
//                 if (val.length > 0) {
//                     newObj[key] = val.map(item => clean(item));
//                 } else {
//                     newObj[key] = [];
//                 }
//             } else if (_.isPlainObject(val)) {
//                 newObj[key] = _.cloneDeep(clean(val));
//             } else if (key !== '__typename') {
//                 newObj[key] = _.cloneDeep(val);
//             }
//         });

//         return newObj;
//     }

//     console.log('### After:', d);
//     return d;
// };

const reducer: Reducer<EditCombosState, EditComboAction> = (state = initialState, action) => {
    let tempState: EditCombosState;
    let tempEditCombo: EditCombo | undefined;
    // let panelPtr: PanelPtr;
    let panel: Panel;
    // let panels;
    // let panel: Editor | Chooser | null;
    // let editorPanel: Panel | undefined;
    // let chooser: Chooser | null;
    // let editor: Editor | null;
    // let panelData: any;
    let tempStatus;
    // let subPages;
    let subPage: SubPage | undefined;
    let isChanged: boolean;
    // let subPageStatus;
    let data: EditorData['db'] | null;
    let newData: EditorData['db'] | null;
    let newSections;
    // let selectedIds;
    // let editorState;
    let mtch: string[] | null;
    let pathParent: string | undefined;
    let arrayIndex: number | undefined;

    const getEditCombo = (s: EditCombosState, name: EditComboName) => s.find(editCombo => editCombo.name === name);

    const getSubPage = (editCombo: EditCombo | undefined, name: string) => {
        const sP = editCombo?.subPages?.find(s => s.name === name);

        if (!sP) throw new Error('editCombo reducer.getSubPage - subPage not found');

        return sP;
    };

    const getPanel = (s: EditCombosState, pP: PanelPtr) => {
        // console.log('editCombo reducer.getPanel - panelPtr:', pP);
        const eC = s.find(e => e.name === pP.editComboName);
        const sP = getSubPage(eC, pP.subPageName);
        const pnl = sP?.panels.find(
            p => p.panelGrpName === (pP.panelGrpName ?? null) && p.attrs.componentType === pP.componentType
        );

        if (!pnl) {
            // console.log('sP.panels:', sP.panels);
            throw new Error(`editCombo reducer.getPanel - no ${pP.componentType} panel`);
        }

        return pnl;
    };

    switch (action.type) {
        case SET_EDIT_COMBO:
            // tempState = _.cloneDeep(action.editCombos);
            // tempState.forEach(e => {
            //     e.subPages.forEach(s => {
            //         if (s.hasPanels.includes('chooser' as SubPagePanel)) s.chooser = { config: null, data: null };
            //         if (s.hasPanels.includes('editor' as SubPagePanel)) s.editor = { config: null, data: null };
            //     });
            // });

            // tempState = action.editCombos.map(e => {
            //     const newSubPages = e.subPages.map(s => ({
            //         ...s,
            //         chooser: s.hasPanels.includes('chooser' as SubPagePanel) ? { config: null, data: null } : undefined,
            //         editor: s.hasPanels.includes('editor' as SubPagePanel) ? { config: null, data: null } : undefined
            //     }));
            //     return { ...e, subPages: newSubPages };
            // });
            tempState = _.cloneDeep(state);
            if (!tempState.find(eC => eC.name === action.editCombo.name)) tempState.push(_.cloneDeep(action.editCombo));
            // tempState[action.editCombo.name] = _.cloneDeep(action.editCombo);
            return tempState;

        case SET_CURR_SUBPAGE_NAME:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            tempEditCombo = getEditCombo(tempState, action.editComboName);

            if (tempEditCombo) tempEditCombo.status.currSubPageName = action.subPageName;
            return tempState;

        // case SET_CHOOSER_SELECTED_ID:
        //     tempState = _.cloneDeep(state);
        //     tempEditCombo = getEditCombo(tempState, action.editComboName);

        //     subPage = getSubPage(tempEditCombo, action.subPageName);

        //     if (subPage) {
        //         subPage.chooserSelectedId = action.itemId;
        //     } else {
        //         console.log('editCombo reducer.SET_CHOOSER_SELECTED_ID - no subPage');
        //     }
        //     return tempState;

        // case SET_CHOOSER_MULTI_SEL_IDS:
        //     tempState = _.cloneDeep(state);
        //     tempEditCombo = getEditCombo(tempState, action.editComboName);

        //     subPage = getSubPage(tempEditCombo, action.subPageName);

        //     if (subPage) {
        //         subPage.chooserMultiSelIds = _.cloneDeep(action.itemIds);
        //     } else {
        //         console.log('editCombo reducer.SET_CHOOSER_MULTI_SEL_IDS - no subPage');
        //     }
        //     return tempState;

        case SET_STATUS:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            tempEditCombo = getEditCombo(tempState, action.editComboName);

            if (tempEditCombo) {
                tempStatus = tempEditCombo.status;
                tempEditCombo.status = { ...tempStatus, ...action.statusObj };
            }
            return tempState;

        case SET_SUBPAGE_STATUS:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            tempEditCombo = getEditCombo(tempState, action.panelPtr.editComboName);

            subPage = getSubPage(tempEditCombo, action.panelPtr.subPageName);
            if (!subPage.status) subPage.status = {};
            // console.log('subPageStatus, action.statusObj:', subPageStatus, action.statusObj);

            if (subPage) {
                // if (subPage.status) {
                subPage.status = { ...subPage.status, ...action.statusObj };
                // tempState[action.editComboName].subPages[action.subPageName].status = subPageStatus;
                // } else {
                //     subPage.status = { ...action.statusObj };
                // }
            } else {
                console.log('editCombo reducer.SET_SUBPAGE_STATUS - subPage not found:', action.panelPtr.subPageName);
            }
            return tempState;

        case SET_CHOOSER_CONFIG:
            tempState = _.cloneDeep(state);
            // panelPtr = action.panelPtr;
            // tempEditCombo = tempState.find(eC => eC.name === panelPtr.editComboName);
            // subPage = getSubPage(tempEditCombo, panelPtr.subPageName);
            // panel = subPage?.panels.find(
            //     p => p.panelGrpName === panelPtr.panelGrpName && p.attrs.componentType === 'chooser'
            // );
            panel = getPanel(tempState, action.panelPtr);
            // chooser = subPage?.chooser ?? null;

            // if (chooser) {
            //     chooser.config = _.cloneDeep(action.chooserConfig);
            // } else {
            //     console.log('editCombo reducer.SET_CHOOSER_CONFIG - no chooser to assign config to');
            // }
            if (panel.attrs.componentType === 'chooser') {
                panel.attrs.config = _.cloneDeep(action.chooserConfig);
            } else {
                console.log('editCombo reducer.SET_CHOOSER_CONFIG - no chooser panel');
            }

            return tempState;

        case SET_CHOOSER_DATA:
            tempState = _.cloneDeep(state);
            // panelPtr = action.panelPtr;
            // tempEditCombo = tempState.find(eC => eC.name === panelPtr.editComboName);
            // subPage = getSubPage(tempEditCombo, panelPtr.subPageName);
            // panel = subPage?.panels.find(
            //     p => p.panelGrpName === panelPtr.panelGrpName && p.attrs.componentType === 'chooser'
            // );
            panel = getPanel(tempState, action.panelPtr);
            // chooser = subPage?.chooser ?? null;

            // if (chooser) {
            //     // console.log('chooser.config is:', chooser.config);
            //     // console.log('About to set to:', _.cloneDeep(action.chooserData));
            //     chooser.data = _.cloneDeep(action.chooserData);
            // } else {
            //     console.log('editCombo reducer.SET_CHOOSER_DATA - no chooser to assign data to');
            // }
            if (panel.attrs.componentType === 'chooser') {
                panel.attrs.data = _.cloneDeep(action.chooserData);
            } else {
                console.log('editCombo reducer.SET_CHOOSER_DATA - no chooser panel');
            }

            return tempState;

        case SET_EDITOR_CONFIG:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            // subPage = getSubPage(tempEditCombo, action.subPageName);
            // editor = subPage?.editor ?? null;
            panel = getPanel(tempState, action.panelPtr);

            // if (editor) {
            //     editor.config = _.cloneDeep(action.editorConfig);
            // } else {
            //     console.log('editCombo reducer.SET_EDITOR_CONFIG - no editor to assign config to');
            // }
            if (panel.attrs.componentType === 'editor') {
                panel.attrs.config = _.cloneDeep(action.editorConfig);
            } else {
                console.log('editCombo reducer.SET_EDITOR_CONFIG - no editor panel');
            }

            return tempState;

        case SET_EDITOR_DATA:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            // subPage = getSubPage(tempEditCombo, action.subPageName);
            // editor = subPage?.editor ?? null;
            // tempEditCombo = tempState.find(eC => eC.name === action.panelPtr.editComboName);
            tempEditCombo = getEditCombo(tempState, action.panelPtr.editComboName);
            subPage = getSubPage(tempEditCombo, action.panelPtr.subPageName);
            panel = getPanel(tempState, action.panelPtr);

            // data = clean(action.editorData.data);
            data = _.cloneDeep(action.editorData);
            // console.log('--> New data:', data.data);
            // if (subPage && editor && data) {
            //     if (!editor.data) editor.data = { db: null, local: null };
            //     editor.data[action.location] = data;
            //     // if (!subPage.status) subPage.status = {};
            //     subPage.status.changed = !_.isEqual(editor.data.db, editor.data.local);
            // } else {
            //     console.log('editCombo reducer.SET_EDITOR_DATA - no editor to assign data to');
            // }
            if (panel.attrs.componentType === 'editor') {
                if (!panel.attrs.data) panel.attrs.data = { db: null, local: null };
                panel.attrs.data[action.location] = data;
                // if (!subPage.status) subPage.status = {};
                isChanged = !_.isEqual(panel.attrs.data.db, panel.attrs.data.local);
                subPage.status = subPage.status ? { ...subPage.status, changed: isChanged } : { changed: isChanged };
                // panel.attrs.data = data;
            } else {
                console.log('editCombo reducer.SET_EDITOR_DATA - no editor panel');
            }

            return tempState;

        case UPDATE_EDITOR_DATA:
            console.log(
                'updateEditorData - panelPtr, action.path, action.data:',
                action.panelPtr,
                action.path,
                action.data
            );
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            // subPage = getSubPage(tempEditCombo, action.subPageName);
            // editor = subPage?.editor ?? null;
            // tempEditCombo = tempState.find(eC => eC.name === action.panelPtr.editComboName);
            tempEditCombo = getEditCombo(tempState, action.panelPtr.editComboName);
            subPage = getSubPage(tempEditCombo, action.panelPtr.subPageName);
            panel = getPanel(tempState, action.panelPtr);

            // if (subPage && editor && editor.data && editor.data[action.location]) {
            //     // _.set({}.valueOf.call(editor.data[action.location]), action.path, _.cloneDeep(action.data));
            //     // eslint-disable-next-line
            //     // @ts-ignore
            //     _.set(editor.data[action.location], action.path, _.cloneDeep(action.data));
            //     // if (!subPage.status) subPage.status = {};
            //     subPage.status.changed = !_.isEqual(editor.data.db, editor.data.local);
            // } else {
            //     console.log('editCombo reducer.UPDATE_EDITOR_DATA - no editor data to update:', action.location);
            // }
            if (panel.attrs.componentType === 'editor') {
                if (!panel.attrs.data) panel.attrs.data = { db: null, local: null };
                data = panel.attrs.data[action.location];
                if (data) {
                    _.set(data, action.path, _.cloneDeep(action.data));
                } else {
                    console.log('editCombo reducer.UPDATE_EDITOR_DATA - no data to update');
                }
                panel.attrs.data[action.location] = data;
                // subPage.status.changed = !_.isEqual(panel.attrs.data.db, panel.attrs.data.local);
                isChanged = !_.isEqual(panel.attrs.data.db, panel.attrs.data.local);
                subPage.status = subPage.status ? { ...subPage.status, changed: isChanged } : { changed: isChanged };
            } else {
                console.log('editCombo reducer.SET_EDITOR_DATA - no editor panel');
            }

            return tempState;

        // case SET_EDIT_COMBO_DATA:
        //     // console.log('Saving:', action.editComboName, action.subPageName, action.panel, action.dataLocation, action.dataName, action.data);
        //     tempState = _.cloneDeep(state);
        //     tempEditCombo getEditCombo(tempState[action)editComboName];

        //     panels = getSubPage(tempEditCombo, action.subPageName);
        //     // panel = panels ? panels[action.panel] ?? null : null;

        //     // if (panels) {
        //     //     panel = panels[action.panel] ?? null;

        //     if (!panels) {
        //         console.log('editCombo reducer.SET_EDIT_COMBO_DATA, panels not found');
        //         return tempState;
        //     }

        //     data = action.panel === 'editor' ? clean(action.data) : _.cloneDeep(action.data);

        //     switch (action.panel) {
        //         case 'chooser':
        //             panel = panels?.chooser ?? null;
        //             if (!panel) {
        //                 console.log('editCombo reducer.SET_EDIT_COMBO_DATA, chooser panel not found');
        //                 return tempState;
        //             }

        //             panel.data = data as ChooserData;
        //             break;

        //         case 'editor':
        //             panel = panels?.editor ?? null;
        //             if (!panel) {
        //                 console.log('editCombo reducer.SET_EDIT_COMBO_DATA, editor panel not found');
        //                 return tempState;
        //             }

        //             switch (action.dataLocation) {
        //                 case 'config':
        //                     panel.config = data as EditorConfig;
        //                     break;

        //                 case 'db':
        //                 case 'local':
        //                     panel.data[action.dataLocation] = data as EditorData;
        //                     break;

        //                 default:
        //                     console.log(
        //                         'editCombo reducer.SET_EDIT_COMBO_DATA, invalid action.dataLocation:',
        //                         action.dataLocation
        //                     );
        //                     return tempState;
        //             }
        //             break;

        //         default:
        //             console.log('editCombo reducer.SET_EDIT_COMBO_DATA, invalid action.panel:', action.panel);
        //             return tempState;
        //     }
        //     // }

        //     // panelData = tempState[action.editComboName].subPages[action.subPageName][action.panel];
        //     // panelData[action.dataLocation] = { ...panelData[action.dataLocation], [action.dataName]: data };
        //     // return updatePanel(
        //     //     state,
        //     //     action.editComboName,
        //     //     action.subPageName,
        //     //     action.panel,
        //     //     // action.dataLocation,
        //     //     panelData
        //     // );

        //     // if (tempState) tempState[action.editComboName].subPages[action.subPageName][action.panel].data.local = data;
        //     return tempState;

        case SET_PERSONALITY_FLDS:
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === 'user');
            // subPage = getSubPage(tempEditCombo, 'edit');
            // // editor = state[0].subPages?.edit?.panels.editor ?? null;
            // editor = subPage?.editor ?? null;
            panel = getPanel(tempState, action.panelPtr);

            // if (!panel || !panel.panel || panel.panelType !== 'editor') {
            // if (!editor || !editor.config) {
            //     console.log('editCombo reducer.SET_PERSONALITY_FIELDS, editor panel not found');
            //     return tempState;
            // }

            // newSections = _.cloneDeep(panel.panel.sections);
            // newSections = _.cloneDeep(editor.config.sections);
            // newSections = _.cloneDeep(panel.attrs.config.sections);

            // if (newSections) {
            //     const sectionIndex = newSections.findIndex(section => section.type === 'personality');
            //     newSections[sectionIndex].flds = action.flds;
            // }

            // _.set(tempState, 'user.subPages.edit.editor.config.sections', newSections);
            if (panel.attrs.componentType === 'editor' && panel.attrs.config) {
                newSections = _.cloneDeep(panel.attrs.config.sections);

                if (newSections) {
                    const sectionIndex = newSections.findIndex(section => section.type === 'personality');
                    newSections[sectionIndex].flds = action.flds;
                }

                panel.attrs.config.sections = newSections;
            } else {
                console.log('editCombo reducer.SET_PERSONALITY_FLDS - no sections found');
            }

            return tempState;
        //
        //
        //
        // case TOGGLE_CHOOSER_SELECT:
        //     tempState = _.cloneDeep(state);
        //     tempEditCombo = getEditCombo(tempState, action.editComboName);

        //     selectedIds = getSubPage(tempEditCombo, action.subPageName)?.selectedIds;

        //     if (!selectedIds) {
        //         selectedIds = [action.data];
        //     } else if (!selectedIds.includes(action.data)) {
        //         selectedIds.push(action.data);
        //     } else {
        //         selectedIds.splice(selectedIds.indexOf(action.data), 1);
        //     }

        //     return tempState;
        //
        //
        //

        case ADD_ARRAY_ITEM:
            console.log('new addArrayItem - panelPtr, path, fld:', action.panelPtr, action.path, action.fld);
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            // subPage = getSubPage(tempEditCombo, action.subPageName);
            // editor = subPage?.editor ?? null;
            panel = getPanel(tempState, { ...action.panelPtr, componentType: PanelComponentType.editor });
            // newData = editor?.data?.local ?? null;
            if (panel.attrs.componentType !== 'editor')
                throw new Error('editCombo reducer.ADD_ARRAY_ITEM - panel is not an editor');
            newData = panel.attrs.data?.local ?? null;

            mtch = action.path.match(/(.*?).(\d*)$/);
            pathParent = mtch ? mtch[1] : undefined;
            arrayIndex = mtch ? parseInt(mtch[2], 10) : undefined;

            if (pathParent !== undefined && arrayIndex !== undefined && newData) {
                //     // console.log('path, fld:', path, fld);
                //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
                //     const tempData = _.cloneDeep(data);
                const newArr = _.get(newData, pathParent);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let newVal: any = '';
                if (action.fld.flds) {
                    newVal = {};
                    action.fld.flds.forEach(f => {
                        if (f.defaultVal) {
                            if (f.type === 'multiSelect') {
                                newVal[f.name] = JSON.parse(f.defaultVal);
                            } else {
                                newVal[f.name] = f.defaultVal;
                            }
                        }
                    });
                    // console.log('newVal is empty object');
                } else {
                    // console.log('newVal is empty string');
                }

                // if (tempData) {
                if (newArr) {
                    newArr.splice(arrayIndex + 1, 0, newVal);
                    _.set(newData, pathParent, newArr);
                } else {
                    _.set(newData, pathParent, [newVal]);
                }
                // }
                //     dispatch(actions.setEditorData(editComboName, subPageName, 'local', { dataType, data: tempData }));
                //     // setData('local', tempData);
                //     // onChange(fld, pathParent, tempData);
            } else {
                console.log(
                    'editCombo reducer.ADD_ARRAY_ITEM - pathParent, arrayIndex, newData:',
                    pathParent,
                    arrayIndex,
                    newData
                );
            }

            return tempState;

        case DELETE_ARRAY_ITEM:
            // console.log('new deleteArrayItem - path:', action.path);
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);
            // subPage = getSubPage(tempEditCombo, action.subPageName);
            // editor = subPage?.editor ?? null;
            panel = getPanel(tempState, { ...action.panelPtr, componentType: PanelComponentType.editor });
            // newData = editor?.data?.local ?? null;
            if (panel.attrs.componentType !== 'editor')
                throw new Error('editCombo reducer.DELETE_ARRAY_ITEM - panel is not an editor');
            newData = panel.attrs.data?.local ?? null;

            mtch = action.path.match(/(.*?).(\d*)$/);
            pathParent = mtch ? mtch[1] : undefined;
            arrayIndex = mtch ? parseInt(mtch[2], 10) : undefined;

            if (pathParent !== undefined && arrayIndex !== undefined && newData) {
                const currArr = _.get(newData, pathParent);
                currArr.splice(arrayIndex, 1);
            } else {
                console.log(
                    'editCombo reducer.DELETE_ARRAY_ITEM - pathParent, arrayIndex, newData:',
                    pathParent,
                    arrayIndex,
                    newData
                );
            }

            return tempState;

        case REVERT_EDITOR_DATA:
            // console.log('revertEditorData - path:', action.path);
            tempState = _.cloneDeep(state);
            // tempEditCombo = tempState.find(eC => eC.name === action.editComboName);

            // editor = getSubPage(tempEditCombo, action.subPageName)?.editor ?? null;
            // tempEditCombo = tempState.find(eC => eC.name === action.panelPtr.editComboName);
            tempEditCombo = getEditCombo(tempState, action.panelPtr.editComboName);
            subPage = getSubPage(tempEditCombo, action.panelPtr.subPageName);
            panel = getPanel(tempState, { ...action.panelPtr, componentType: PanelComponentType.editor });
            // editorState = tempState[action.editComboName].subPages[action.subPageName].editor;
            if (panel.attrs.componentType !== 'editor')
                throw new Error('editCombo reducer.REVERT_EDITOR_DATA - panel is not an editor');

            // if (editorState?.data.db) {
            // if (editorState) editorState.data.local = _.cloneDeep(editorState.data.db);
            // } else {
            //     delete editorState?.data.local;
            // }
            // if (panel && panel.panelType === 'editor') {
            // if (editor && editor.data) {
            if (panel.attrs.data) {
                if (action.path) {
                    const newObj = _.cloneDeep({}.valueOf.call(panel.attrs.data.local));
                    _.set(newObj, action.path, _.cloneDeep(_.get(panel.attrs.data.db, action.path)));
                    panel.attrs.data.local = newObj as EditorData['local'];
                } else {
                    panel.attrs.data.local = _.cloneDeep(panel.attrs.data.db);
                }
            } else {
                console.log('editCombo reducer.REVERT_EDITOR_DATA - editor panel is null');
            }
            // } else {
            //     console.log('editCombo reducer.REVERT_EDITOR_DATA - editor panel not found');
            // }

            // subPageStatus = getSubPage(tempEditCombo, action.subPageName)?.status;
            // if (subPageStatus) subPageStatus.changed = false;
            // subPage.status.changed = false;
            subPage.status = subPage.status ? { ...subPage.status, changed: false } : { changed: false };
            return tempState;

        default:
            return state;
    }
};

export default reducer;
