import React from 'react';

import { ReactComponent as PhoneIcon } from '../../../assets/images/icons/phone.svg';

const ForcePortrait: React.FC = () => (
    <div className="forcePortrait">
        <div className="forcePortrait__darken" />

        <div className="forcePortrait__contents">
            <div className="text">
                PLEASE ROTATE
                <br />
                YOUR PHONE
            </div>

            <div className="icon">
                <PhoneIcon width="100%" height="100%" />
            </div>

            <div className="text text--reversed">
                PLEASE ROTATE
                <br />
                YOUR PHONE
            </div>
        </div>
    </div>
);

export default ForcePortrait;
