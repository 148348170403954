// import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import _ from 'lodash';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Q, M } from '../helpers/gql';

import * as actions from '../store/actions';
import C from '../helpers/constants';

import { State } from '../index';
// import { store } from '../index';

// import { connectSocket, disconnectSocket, disconnectAllSockets, isSocketConnected } from '../helpers/sockets';
import useSockets from '../hooks/use-sockets';

import RealtimeContext from '../contexts/RealtimeContext';

import { DataType, MembershipFlag } from '../__types__/graphql-global-types';
import { AccessTokenData } from '../store/reducers/auth';

// import { UserData } from '../models/user';
import { User } from '../store/reducers/user';

import { obj, objVariables } from '../helpers/__types__/obj';
import { extendRefreshToken } from '../helpers/__types__/extendRefreshToken';

type CookieStatus = 'unchanged' | 'appeared' | 'changed' | 'disappeared';

const REFRESH_INTERVAL = 1000;

const useAccessToken = (): { startCookieReadLoop: () => void; signOut: () => void } => {
    const dispatch = useDispatch();

    // prettier-ignore
    // const { sockets, socketExists, socketConnecting, socketConnected, connectSocket, disconnectSocket, disconnectAllSockets } = useSockets();
    // const { sockets, socketExists, socketConnecting, socketConnected, connectSocket, disconnectSocket } = useSockets();
    const { sockets, socketExists, socketConnecting, socketConnected, connectSocket, disconnectSocket } = useSockets();

    const debug = useSelector((state: State) => state.main.debug);
    const user = useSelector((state: State) => state.user.data);
    const aTData = useSelector((state: State) => state.auth.aTData);
    const signedIn = useSelector((state: State) => state.auth.signedIn);

    // const serverTime = useRef(0);
    const currTime = useRef(0);

    const { triggerReadCookie, rtDispatch } = useContext(RealtimeContext);

    const [loadUser] = useLazyQuery<obj, objVariables>(Q.GET_OBJ, {
        onCompleted: res => {
            const data = _.cloneDeep(res.obj);
            if (data?.__typename !== 'User')
                throw new Error(`useAccessToken.loadUser returned __typename: ${data?.__typename}`);

            // data.personality = JSON.parse(data.personality);
            const userData = data as User | null;
            console.log('useAccessToken loadUser res:', userData);
            dispatch(actions.setUserData(userData));
            dispatch(actions.setSignedIn(true));

            if (userData?.membership.flags.includes(MembershipFlag.payOnNextSignIn))
                dispatch(actions.setSelPage('landing', '/billing'));

            setNeedToLoadUser(false);
            setLoadingUser(false);
        },
        onError: e => {
            console.log('useAccessToken, userLoadingError', e.message);
        }
    });

    const [execExtendRefreshToken, { loading: extendingRefreshToken }] = useMutation<extendRefreshToken>(
        M.AUTH_EXTEND_REFRESH_TOKEN,
        {
            onCompleted: () => {
                // console.log('Token was extended');
            },
            onError: e => {
                dispatch(actions.addProcessDebugData('signOut', `uAT.extendRefreshToken - error: ${e.message}`));
                // console.log('useAccessToken.extendRefreshToken.error - Removing cookie');
                // Cookies.remove('attest', { domain: C.cookieDomain });
                // dispatch(actions.setError('popup', { message: 'Network error. Please sign in again.' }));
                if (debug)
                    dispatch(
                        actions.setError({
                            groupName: 'popup',
                            error: {
                                message: `Sign out bug detected: ${new Date(Date.now()).toTimeString()}`
                            }
                        })
                    );
                console.log('***** extendRefreshToken error:', e.message);
            }
        }
    );

    // const [currTime, setCurrTime] = useState(0);
    // const [aTSecsLeft, setATSecsLeft] = useState<number | null>(null);
    // const [rTSecsLeft, setRTSecsLeft] = useState<number | null>(null);
    const [needToRead, setNeedToRead] = useState(false);
    const [tick, setTick] = useState(false);
    const [cookieStatus, setCookieStatus] = useState<CookieStatus>('unchanged');
    const [cookieReadRetries, setCookieReadRetries] = useState(3);
    const [needToLoadUser, setNeedToLoadUser] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);

    const [signOutUser, { loading: signingOut, error: signOutError }] = useMutation(M.AUTH_SIGN_OUT, {
        onCompleted: result => {
            // console.log('signOut received:', result);
            dispatch(
                actions.addProcessDebugData('signOut', `uAT.signOutUser - server returned ${result ? 'true' : 'false'}`)
            );
            rtDispatch({ type: 'setTriggerReadCookie', triggerReadCookie: true });
            // nullifyUser();
        }
    });

    if (signOutError) console.log('useAccessToken.signOut - error:', signOutError);

    const startCookieReadLoop = () => {
        setInterval(() => {
            // rtDispatch({ type: 'setTriggerReadCookie', triggerReadCookie: true });
            // serverTime.current = serverTime.current + REFRESH_INTERVAL;

            // if (socketConnected('/adminSignedIn')) {
            //     dispatch(actions.addProcessDebugData('signOut', 'signOut - about to disconnect socket /adminSignedIn'));
            //     disconnectSocket('/adminSignedIn');
            // }

            setNeedToRead(true);
        }, REFRESH_INTERVAL);
    };

    const signOut = useCallback(() => {
        console.log('signOut');

        // if (aTData) {
        //     dispatch(actions.addProcessDebugData('signOut', 'signOut - about to nullify atData'));
        //     dispatch(actions.setATData(null));
        // }

        if (signedIn) {
            dispatch(actions.setSignedIn(false));
            dispatch(actions.addProcessDebugData('signOut', 'uAT.signOut - setSignedIn(false)'));

            dispatch(actions.addProcessDebugData('signOut', 'signOut - about to remove attest cookie'));
            Cookies.remove('attest', { domain: C.cookieDomain });

            if (socketConnected('/adminSignedIn')) {
                dispatch(
                    actions.addProcessDebugData('signOut', 'uAT.signOut - about to disconnect socket /adminSignedIn')
                );
                disconnectSocket('/adminSignedIn');
            }

            if (user) {
                dispatch(actions.addProcessDebugData('signOut', 'uAT.nullilfyUser - start 2 second timer'));

                setTimeout(() => {
                    dispatch(actions.jumpToSection('/'));
                    dispatch(actions.setUserData(null));
                    dispatch(actions.addProcessDebugData('signOut', 'uAT.nullilfyUser - user nullified'));
                }, 2000);
            } else {
                dispatch(actions.addProcessDebugData('signOut', 'uAT.nullifyUser - skipped because no user'));
            }

            if (!signingOut) {
                dispatch(actions.addProcessDebugData('signOut', 'uAT.signOut - about to sign out from server'));
                signOutUser();
            } else {
                dispatch(actions.addProcessDebugData('signOut', 'uAT.signOut - skipped because already signing out'));
            }
        } else {
            dispatch(actions.addProcessDebugData('signOut', 'uAT.signOut - skipped because not signedIn'));
        }
    }, [signedIn, user, signingOut, dispatch, socketConnected, disconnectSocket, signOutUser]);
    // }, [signingOut, user, dispatch, signOutUser]);
    // }, []);

    useEffect(() => {
        if (needToRead) {
            const cookie = Cookies.get('attest');
            if (aTData && !cookie)
                dispatch(
                    actions.addProcessDebugData(
                        'signOut',
                        `uAT - ${currTime.current} - aTData, but Cookies.get returned: ${typeof cookie}`
                    )
                );

            let newATData: AccessTokenData | null = null;
            let newCookieStatus: CookieStatus = 'unchanged';
            let newCurrTime = 0;

            try {
                newATData = jwt.decode(cookie ?? '') as AccessTokenData;
                newCurrTime = newATData.n;
                setCookieReadRetries(3);
            } catch (e) {
                const retriesLeft = cookieReadRetries - 1;
                if (retriesLeft > 0) {
                    dispatch(
                        actions.addProcessDebugData(
                            'signOut',
                            `uAT - ${Date.now()} - cookie decode error, retries left: ${retriesLeft}`
                        )
                    );
                    setCookieReadRetries(retriesLeft);
                    // if (!extendingRefreshToken) {
                    //     dispatch(
                    //         actions.addProcessDebugData(
                    //             'signOut',
                    //             `uAT - cookie decode error, about to extendRefreshToken()`
                    //         )
                    //     );
                    //     extendRefreshToken();
                    // }
                    newATData = _.cloneDeep(aTData);
                    // } else {
                    //     dispatch(actions.addProcessDebugData('signOut', 'uAT - cookie decode error, no retries left'));
                }
            }

            if (newATData && !aTData) {
                newCookieStatus = 'appeared';
                if (debug) console.log('new cookie .n:', newCurrTime);
            } else if (!newATData && aTData) {
                newCookieStatus = 'disappeared';
                dispatch(actions.addProcessDebugData('signOut', 'uAT - setting cookieStatus to disappeared'));
            } else if (!_.isEqual(newATData, aTData)) {
                newCookieStatus = 'changed';
                if (debug) console.log('changed cookie .n:', newCurrTime);
            } else {
                newCurrTime = currTime.current + REFRESH_INTERVAL;
            }

            if (!_.isEqual(newATData, aTData)) {
                if (debug) console.log('Setting aTData:', aTData);
                dispatch(actions.setATData(newATData));
            }

            if (cookieStatus !== newCookieStatus) {
                // console.log('setting cookieStatus:', newCookieStatus);
                setCookieStatus(newCookieStatus);
            }
            currTime.current = newCurrTime;
            setTick(true);
            setNeedToRead(false);
        }
        // }, [needToRead, aTData, cookieReadRetries, extendingRefreshToken, cookieStatus, dispatch, extendRefreshToken]);
    }, [needToRead, aTData, cookieReadRetries, cookieStatus, debug, dispatch]);

    useEffect(() => {
        if (tick) {
            let aTSecsLeft = null;
            let rTSecsLeft = null;
            const currSecs = Math.round(currTime.current / 1000);

            // console.log(
            //     'aTExp, currTime, aTSecsLeft:',
            //     aTData ? aTData.aTExp : 'no aTData',
            //     currSecs,
            //     aTData ? aTData.aTExp - currSecs : 'no aTData'
            // );

            if (aTData) {
                aTSecsLeft = aTData.aTExp - currSecs;
                rTSecsLeft = aTData.rTExp - currSecs;

                // if (typeof aTSecsLeft === 'number') {
                const aTRenew = aTData ? aTData.aTRenew : 0;
                if (debug) console.log(`aTSecsLeft: ${aTSecsLeft}, rTSecsLeft: ${rTSecsLeft}, aTRenew: ${aTRenew}`);

                if (aTSecsLeft <= 0) {
                    dispatch(
                        actions.addProcessDebugData('signOut', `uAT - atSecsLeft: ${aTSecsLeft}, calling signOut`)
                    );
                    signOut();
                } else if (aTSecsLeft <= aTRenew) {
                    if (!extendingRefreshToken) {
                        if (debug) console.log('About to extendRefreshToken at:', Date.now());
                        execExtendRefreshToken();
                    } else {
                        console.log('Already extendingRefreshToken');
                    }
                }
            } else if (signedIn) {
                dispatch(actions.addProcessDebugData('signOut', 'uAT - signedIn=true, aTData=null - calling signOut'));
                signOut();
            }

            rtDispatch({ type: 'setSecsLeft', aTSecsLeft, rTSecsLeft });
            rtDispatch({ type: 'setIndicator', indicatorType: 'debugATSecs', val: true });

            if (cookieStatus === 'appeared' || cookieStatus === 'changed') {
                // console.log('aTData.id, user, loadingUser:', aTData?.id, user, loadingUser);

                if (aTData) {
                    if (aTData.id && (!user || user.id !== aTData.id) && !loadingUser) {
                        console.log('Loading user - id:', aTData.id);
                        setNeedToLoadUser(true);
                        // loadUser({ variables: { dataType: 'User' as DataType, id: aTData.id } });
                    }

                    if (debug) console.log('***** Might try for new admin socket');
                    // If a good access token has appeared during a non-signed-in session
                    // and we're already connected to /admin, join /adminSignedIn
                    if (socketConnected('/admin') && !socketExists('/adminSignedIn')) {
                        if (!socketConnecting('/adminSignedIn')) {
                            if (debug) {
                                console.log('/admin, /adminSignedIn:', sockets['/admin'], sockets['/adminSignedIn']);
                                console.log(
                                    '--> Signing in to /adminSignedIn: cookie appeared',
                                    aTData ? aTData.id : 'No aTData'
                                );
                            }
                            connectSocket('/adminSignedIn');
                        } else {
                            console.log('Skipped because already connecting: /adminSignedIn');
                        }
                    }
                } else {
                    console.log('strange cookieStatus / atData');
                }
            } else if (cookieStatus === 'disappeared' && signedIn) {
                dispatch(actions.addProcessDebugData('signOut', 'uAT - atData disappeared, calling signOut'));
                signOut();
            }

            setTick(false);
        }
    }, [
        tick,
        debug,
        aTData,
        user,
        currTime,
        extendingRefreshToken,
        loadingUser,
        signedIn,
        cookieStatus,
        sockets,
        dispatch,
        rtDispatch,
        execExtendRefreshToken,
        loadUser,
        signOut,
        connectSocket,
        socketConnected,
        socketConnecting,
        socketExists
    ]);

    useEffect(() => {
        if (triggerReadCookie) {
            console.log('Got triggerReadCookie from Realtime');
            setNeedToRead(true);
        }
    }, [triggerReadCookie]);

    useEffect(() => {
        if (aTData?.id) {
            if (needToLoadUser) {
                setLoadingUser(true);
                loadUser({ variables: { dataType: 'User' as DataType, id: aTData.id } });
            }
        } else {
            console.log('useAccessToken - no aTData.id');
        }
    }, [needToLoadUser, aTData, loadUser]);

    return { startCookieReadLoop, signOut };
};

export default useAccessToken;
