import * as actionTypes from './actionTypes';

import { AccessTokenData, AuthAction } from '../reducers/auth';

export const setSignedIn = (signedIn: boolean): AuthAction => ({ type: actionTypes.SET_SIGNED_IN, signedIn });

export const setATData = (aTData: AccessTokenData | null): AuthAction => ({
    type: actionTypes.SET_AT_DATA,
    aTData
});

// export const setATDisappeared = (aTDisappeared: boolean): AuthAction => ({ type: actionTypes.SET_AT_DISAPPEARED, aTDisappeared });

export const setRememberMe = (rememberMe: boolean): AuthAction => ({
    type: actionTypes.SET_REMEMBER_ME,
    rememberMe
});
