import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { State } from '../../../index';

const Alert: React.FC = () => {
    const defaultParams = { level: 'info', block: true, canClose: true };

    const alert = useSelector((state: State) => state.comm.alert);
    const { level, block, canClose, message, html } = { ...defaultParams, ...(alert ?? {}) };

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (alert) setShow(true);
    }, [alert]);

    // return alert && alert.block && show ? (
    return alert && show ? (
        <div className="alert">
            {block && <div className="alert__dimmer" />}

            <div className="alert__contentsContainer" onClick={canClose ? () => setShow(false) : () => ({})}>
                <div className={`contents ${level}`}>
                    {message && <div className="message">{alert.message}</div>}
                    {html && <div className="html">{alert.html}</div>}

                    {canClose ? (
                        <div className="closeBtn" onClick={() => setShow(false)}>
                            Close
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    ) : null;
};

export default Alert;
