import { Reducer } from 'redux';

import _ from 'lodash';

import { getPages_getPages as Page } from '../../helpers/__types__/getPages';

import {
    SET_UI_VAR,
    SET_NEED_READ_RM_COOKIE,
    SET_ARE_TEMP_CREDENTIALS,
    SET_VERSIONS,
    SET_SCREEN_DIMS,
    SET_SCROLL_AMT,
    SET_INPUT_FOCUSED,
    SET_COMING_SOON,
    SET_INITIALIZING,
    SET_SHOW_ADMIN,
    SET_DEBUG,
    ADD_PROCESS_DEBUG_DATA,
    SET_SHOW_PROCESS_DEBUG_DATA,
    SET_DEMO_MODE,
    SET_PLEASE_WAIT,
    CLEAR_PLEASE_WAIT
} from '../actions/actionTypes';

export type Versions = {
    api: string;
    min: string;
    rec: string;
};

export type ScreenDims = { width: number; height: number };

export type ScrollAmt = { x: number; y: number };

export type ProcessName = 'signOut';

export type ProcessDebugData = { processName: ProcessName; data: string };

export type MainState = {
    uiVars: { [key: string]: string | number | boolean };
    needReadRMCookie: boolean;
    areTempCredentials: boolean;
    versions: Versions | null;
    screenDims: ScreenDims;
    scrollAmt: ScrollAmt;
    inputFocused: boolean;
    comingSoon: boolean;
    initializing: boolean;
    pagesLoaded: boolean;
    showAdmin: boolean;
    debug: boolean;
    processDebugData?: ProcessDebugData[];
    showProcessDebugData: ProcessName | null;
    demoMode: boolean;
    pleaseWait: string[];
    pages: Page[];
};

export type MainAction =
    | { type: 'SET_UI_VAR'; path: string; val: string | number | boolean }
    | { type: 'SET_NEED_READ_RM_COOKIE'; needReadRMCookie: boolean }
    | { type: 'SET_ARE_TEMP_CREDENTIALS'; areTempCredentials: boolean }
    | { type: 'SET_VERSIONS'; versions: Versions | null }
    | { type: 'SET_SCREEN_DIMS'; screenDims: ScreenDims }
    | { type: 'SET_SCROLL_AMT'; scrollAmt: ScrollAmt }
    | { type: 'SET_INPUT_FOCUSED'; inputFocused: boolean }
    | { type: 'SET_COMING_SOON'; comingSoon: boolean }
    | { type: 'SET_INITIALIZING'; initializing: boolean }
    | { type: 'SET_SHOW_ADMIN'; showAdmin: boolean }
    | { type: 'SET_DEBUG'; debug: boolean }
    | { type: 'ADD_PROCESS_DEBUG_DATA'; processName: ProcessName; data: string }
    | { type: 'SET_SHOW_PROCESS_DEBUG_DATA'; processName: ProcessName | null }
    | { type: 'SET_DEMO_MODE'; demoMode: boolean }
    | { type: 'SET_PLEASE_WAIT' | 'CLEAR_PLEASE_WAIT'; target: string };

const initialState = {
    uiVars: {},
    needReadRMCookie: true,
    areTempCredentials: false,
    versions: null,
    screenDims: { width: window.innerWidth, height: window.innerHeight },
    scrollAmt: { x: 0, y: 0 },
    inputFocused: false,
    comingSoon: false,
    initializing: true,
    pagesLoaded: false,
    showAdmin: false,
    debug: false,
    showProcessDebugData: null,
    demoMode: false,
    pleaseWait: [],
    pages: []
};

const reducer: Reducer<MainState, MainAction> = (state = initialState, action) => {
    let newUIVars;
    let newPleaseWait: string[];
    let newProcessDebugData;

    switch (action.type) {
        case SET_UI_VAR:
            newUIVars = _.cloneDeep(state.uiVars);
            _.set(newUIVars, action.path, _.cloneDeep(action.val));
            return { ...state, uiVars: newUIVars };

        case SET_NEED_READ_RM_COOKIE:
            return { ...state, needReadRMCookie: action.needReadRMCookie };

        case SET_ARE_TEMP_CREDENTIALS:
            return { ...state, areTempCredentials: action.areTempCredentials };

        case SET_VERSIONS:
            return { ...state, versions: _.cloneDeep(action.versions) };

        case SET_SCREEN_DIMS:
            return { ...state, screenDims: _.cloneDeep(action.screenDims) };

        case SET_SCROLL_AMT:
            return { ...state, scrollAmt: _.cloneDeep(action.scrollAmt) };

        case SET_INPUT_FOCUSED:
            return { ...state, inputFocused: action.inputFocused };

        case SET_COMING_SOON:
            return { ...state, comingSoon: action.comingSoon };

        case SET_INITIALIZING:
            return { ...state, initializing: action.initializing };

        case SET_SHOW_ADMIN:
            return { ...state, showAdmin: action.showAdmin };

        case SET_DEBUG:
            return { ...state, debug: action.debug };

        case ADD_PROCESS_DEBUG_DATA:
            newProcessDebugData = _.cloneDeep(state.processDebugData) ?? [];
            newProcessDebugData.push({ processName: action.processName, data: action.data });
            console.log('addProcessDebugData:', action.data);

            return { ...state, processDebugData: newProcessDebugData };

        case SET_SHOW_PROCESS_DEBUG_DATA:
            return { ...state, showProcessDebugData: action.processName };

        case SET_DEMO_MODE:
            return { ...state, demoMode: action.demoMode };

        case SET_PLEASE_WAIT:
            newPleaseWait = [...state.pleaseWait];
            if (!newPleaseWait.includes(action.target)) newPleaseWait.push(action.target);

            return { ...state, pleaseWait: newPleaseWait };

        case CLEAR_PLEASE_WAIT:
            newPleaseWait = [...state.pleaseWait];
            if (action.target && newPleaseWait.includes(action.target))
                newPleaseWait.splice(newPleaseWait.indexOf(action.target), 1);

            return { ...state, pleaseWait: newPleaseWait };

        default:
            return state;
    }
};

export default reducer;
