import { Reducer } from 'redux';

import _ from 'lodash';

import { SET_USER_DATA, SET_USER_PREFERENCE } from '../actions/actionTypes';

import { userAll, userAll_preferences as userAllPreferences } from '../../helpers/__types__/userAll';

export type User = userAll;
export type Preference = userAllPreferences;

export type UserState = {
    data: User | null;
};

export type UserAction =
    | { type: 'SET_USER_DATA'; data: User | null }
    | { type: 'SET_USER_PREFERENCE'; preference: Preference };

const initialState = {
    data: null
};

const reducer: Reducer<UserState, UserAction> = (state = initialState, action) => {
    let data;
    const newData = _.cloneDeep(state.data);
    let newPref;

    switch (action.type) {
        case SET_USER_DATA:
            data = _.cloneDeep(action.data);

            // if (data && data.mustCreateNewUser) {
            //     data.memberName = '';
            //     data.hasPassword = false;
            //     data.mustCreateNewUser = false;
            // }

            return { ...state, data };

        case SET_USER_PREFERENCE:
            newPref = _.cloneDeep(action.preference);

            if (!newData) {
                console.log('user.SET_USER_PREFERENCE: Trying to set preference for undefined user');
            } else {
                if (!newData.preferences) newData.preferences = [];
                const currIndex = newData.preferences.findIndex(p => p.name === action.preference.name);

                if (currIndex === -1) {
                    newData.preferences.push(newPref);
                } else {
                    newData.preferences[currIndex] = newPref;
                }
            }

            return { ...state, data: newData };

        default:
            return state;
    }
};

export default reducer;
