/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionBtnType {
  billing = "billing",
  none = "none",
  surveyOptOut = "surveyOptOut",
}

export enum CatalogItemsType {
  columnLabels = "columnLabels",
  columns = "columns",
  none = "none",
  rowLabels = "rowLabels",
  rows = "rows",
}

export enum CatalogMode {
  matchRules = "matchRules",
}

export enum CorrespondenceMethod {
  email = "email",
  sms = "sms",
}

export enum CorrespondencePurpose {
  approve = "approve",
  invite = "invite",
}

export enum CorrespondenceStatus {
  sent = "sent",
  unsent = "unsent",
}

export enum DataType {
  EmailTemplate = "EmailTemplate",
  InfoBox = "InfoBox",
  InviteCode = "InviteCode",
  Job = "Job",
  Match = "Match",
  MatchRule = "MatchRule",
  MatchRuleSet = "MatchRuleSet",
  Meet = "Meet",
  Post = "Post",
  Survey = "Survey",
  SurveyItem = "SurveyItem",
  SurveyPage = "SurveyPage",
  System = "System",
  User = "User",
  UserGroup = "UserGroup",
  Venue = "Venue",
}

export enum DetailsEl {
  div = "div",
  span = "span",
}

export enum DirectionPref {
  less = "less",
  more = "more",
  same = "same",
}

export enum EditorType {
  EmailTemplate = "EmailTemplate",
  InfoBox = "InfoBox",
  InviteCode = "InviteCode",
  Job = "Job",
  Match = "Match",
  MatchRule = "MatchRule",
  MatchRuleSet = "MatchRuleSet",
  MatchTasks = "MatchTasks",
  Meet = "Meet",
  Post = "Post",
  Survey = "Survey",
  SurveyItem = "SurveyItem",
  SurveyPage = "SurveyPage",
  System = "System",
  User = "User",
  UserGroup = "UserGroup",
  Venue = "Venue",
}

export enum FlagAction {
  clear = "clear",
  set = "set",
  toggle = "toggle",
}

export enum FldType {
  ansWeights = "ansWeights",
  button = "button",
  checkbox = "checkbox",
  date = "date",
  dateTime = "dateTime",
  dateTimeLocal = "dateTimeLocal",
  email = "email",
  geoPoint = "geoPoint",
  image = "image",
  input = "input",
  link = "link",
  multiSelect = "multiSelect",
  number = "number",
  personality = "personality",
  phoneVerify = "phoneVerify",
  radio = "radio",
  select = "select",
  textarea = "textarea",
  time = "time",
  updatePassword = "updatePassword",
}

export enum FuncName {
  approve = "approve",
  convertDates = "convertDates",
  findMatch = "findMatch",
  genMatchRules = "genMatchRules",
  invite = "invite",
  preMail = "preMail",
  testMessaging = "testMessaging",
  testRtData = "testRtData",
}

export enum Gender {
  f = "f",
  m = "m",
}

export enum JobDataModel {
  User = "User",
}

export enum JobFilterOperator {
  equals = "equals",
}

export enum JobFilterParam {
  gender = "gender",
  lookingFor = "lookingFor",
}

export enum JobProcessType {
  email = "email",
  matchRuleSet = "matchRuleSet",
}

export enum JournalEntryCategory {
  memberExpenses = "memberExpenses",
  membership = "membership",
  referrerCommission = "referrerCommission",
}

export enum JournalEntryCurrency {
  dollars = "dollars",
  meets = "meets",
}

export enum JournalEntryMethod {
  internal = "internal",
  promo = "promo",
  stripeIn = "stripeIn",
  stripeOut = "stripeOut",
  venmoIn = "venmoIn",
  venmoOut = "venmoOut",
}

export enum MatchRuleAlgorithm {
  age = "age",
  ansMatch = "ansMatch",
  height = "height",
  proximity = "proximity",
  wantInPartner = "wantInPartner",
}

export enum MatchRuleBasis {
  history = "history",
  other = "other",
  surveyItem = "surveyItem",
}

export enum MeetMessageType {
  text = "text",
  timeUntilMeetReminder = "timeUntilMeetReminder",
}

export enum MeetParticipantStatusName {
  cancelled = "cancelled",
  comments = "comments",
  late = "late",
  memberChanged = "memberChanged",
  needToRate = "needToRate",
  normal = "normal",
  rating = "rating",
}

export enum MeetStatus {
  active = "active",
  approaching = "approaching",
  cancelled = "cancelled",
  completed = "completed",
  deleted = "deleted",
  inProgress = "inProgress",
  init = "init",
  scheduled = "scheduled",
}

export enum MembershipFlag {
  admin = "admin",
  app = "app",
  approved = "approved",
  autoGen = "autoGen",
  canViewGallery = "canViewGallery",
  disable = "disable",
  includeInGallery = "includeInGallery",
  matchable = "matchable",
  paidReferrer = "paidReferrer",
  payOnNextSignIn = "payOnNextSignIn",
  profile = "profile",
  revenue = "revenue",
  surveyCanSkip = "surveyCanSkip",
  surveyOptedOut = "surveyOptedOut",
  testUser = "testUser",
  web = "web",
  webDemo = "webDemo",
}

export enum OnboardingStatus {
  complete = "complete",
  inProgress = "inProgress",
  notStarted = "notStarted",
}

export enum OptionType {
  date = "date",
  height = "height",
  image = "image",
  phoneVerify = "phoneVerify",
  single = "single",
  textBox = "textBox",
  textInput = "textInput",
  xor = "xor",
}

export enum PageFunc {
  signOut = "signOut",
  toggleDebug = "toggleDebug",
}

export enum PageType {
  editCombo = "editCombo",
  empty = "empty",
  panels = "panels",
}

export enum PanelComponentType {
  catalog = "catalog",
  chooser = "chooser",
  editor = "editor",
  rtDisplay = "rtDisplay",
}

export enum PanelType {
  homeDashboard = "homeDashboard",
}

export enum ReservationStatus {
  active = "active",
  cancelled = "cancelled",
  completed = "completed",
  deleted = "deleted",
  init = "init",
}

export enum RtDataContentsType {
  columns = "columns",
  control = "control",
}

export enum RtDataControlCommand {
  allClear = "allClear",
}

export enum RtDataItemAction {
  append = "append",
  modify = "modify",
}

export enum RtDataMode {
  homeDashboard = "homeDashboard",
  matchDashboard = "matchDashboard",
}

export enum SignUpInMode {
  signIn = "signIn",
  signUp = "signUp",
}

export enum SubFuncName {
  setMeetApproaching = "setMeetApproaching",
  setMeetCancelled = "setMeetCancelled",
  setMeetCompleted = "setMeetCompleted",
  setMeetInProgress = "setMeetInProgress",
  setMeetScheduled = "setMeetScheduled",
}

export enum SurveyItemPerspective {
  partner = "partner",
  self = "self",
}

export enum SurveyPageStyle {
  alt1 = "alt1",
  default = "default",
  noBox = "noBox",
  whiteBox = "whiteBox",
}

export enum SurveyPageType {
  infoBox = "infoBox",
  profile = "profile",
  summary = "summary",
  surveyItems = "surveyItems",
  verifiedPhone = "verifiedPhone",
}

export enum UnreadObjType {
  Meet = "Meet",
  Request = "Request",
}

export enum UserMode {
  admin = "admin",
  normal = "normal",
}

export enum ValidatorType {
  atLeast21 = "atLeast21",
  confirmPassword = "confirmPassword",
  email = "email",
  memberName = "memberName",
  none = "none",
  password = "password",
  phone = "phone",
}

export interface CartItemInput {
  sku: string;
  quantity: number;
}

export interface SortObj {
  fldName?: string | null;
  dir?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
