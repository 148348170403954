import { Reducer } from 'redux';
import _ from 'lodash';

import {
    SET_SURVEY_LOAD_ID,
    SET_SURVEY_CONFIG,
    UPDATE_SURVEY_FROM_EDITOR,
    SET_SURVEY_DATA,
    SET_SURVEY_ERROR,
    CLEAR_SURVEY_ERROR,
    SET_SHOW_PAGE_ERRORS
} from '../actions/actionTypes';

import { EditComboName, SubPageName, Fld } from '../../types/ui/EditCombo';

import { Survey as SurveyType } from '../../models/survey';
import { SurveyPage } from '../../models/surveyPage';
import { SurveyItemContainer } from '../../models/surveyItem';
// import User from '../../models/user';
import { userAll as User } from '../../helpers/__types__/userAll';

export type PathErrors = { path: string; errs: string[] };
export type SurveyContext = 'onboardingEditor' | 'userEditor' | 'onboarding';
// eslint-disable-next-line
export type SurveyItemData = any;
export type SurveyCommonProps = {
    context: SurveyContext;
    pageConfig?: SurveyPage;
    itemContainerConfig?: SurveyItemContainer;
    path?: string;
    surveyData?: User | null;
    itemData?: SurveyItemData | null;
    pageErrors: PathErrors[];
    showErrors: boolean;
};

export type SurveyError = {
    path: string;
    message: string;
};

export type SurveyState = {
    loadId: {
        onboardingEditor: string | null;
        userEditor: string | null;
        onboarding: string | null;
    };
    config: SurveyType | null;
    data: {
        db: User | null;
        local: User | null;
    };
    errors: SurveyError[] | null;
    showPageErrors: { [key: string]: boolean };
};

export type SurveyAction =
    | { type: 'SET_SURVEY_LOAD_ID'; context: SurveyContext; loadId: string }
    | { type: 'SET_SURVEY_CONFIG'; config: SurveyType | null }
    | {
          type: 'UPDATE_SURVEY_FROM_EDITOR';
          editComboId: EditComboName;
          subPageId: SubPageName;
          id: string;
          fld: Fld;
          path: string;
          data: SurveyType;
      }
    | { type: 'SET_SURVEY_DATA'; location: 'db' | 'local'; data: User }
    | { type: 'SET_SURVEY_ERROR'; error: SurveyError }
    | { type: 'CLEAR_SURVEY_ERROR'; path: string }
    | { type: 'SET_SHOW_PAGE_ERRORS'; pageName: string; show: boolean };

const initialState = {
    loadId: {
        onboardingEditor: null,
        userEditor: null,
        onboarding: null
    },
    config: null,
    data: {
        db: null,
        local: null
    },
    errors: null,
    showPageErrors: {}
};

const reducer: Reducer<SurveyState, SurveyAction> = (state = initialState, action) => {
    let path = '';
    let newVal;
    let newErrors: SurveyError[] | null;
    const newLoadId = _.cloneDeep(state.loadId);
    const newConfig = _.cloneDeep(state.config);
    const newData = _.cloneDeep(state.data);
    let index;
    const newShowPageErrors = _.cloneDeep(state.showPageErrors);

    switch (action.type) {
        case SET_SURVEY_LOAD_ID:
            _.set(newLoadId, action.context, action.loadId);
            return { ...state, loadId: newLoadId };

        case SET_SURVEY_CONFIG:
            return { ...state, config: _.cloneDeep(action.config) };

        case UPDATE_SURVEY_FROM_EDITOR:
            // prettier-ignore
            console.log('Made it!;', action.editComboId, action.subPageId, action.id, action.path, action.fld, action.data);
            switch (action.subPageId) {
                case 'surveyPage':
                    console.log('Editor sent surveyPage:', action.id);
                    if (state.config && state.config.surveyPages) {
                        if (action.fld.type === 'select') {
                            console.log('Need to handle select');
                        } else {
                            state.config.surveyPages.forEach((surveyPage, i) => {
                                if (surveyPage?.surveyPage.id === action.id) {
                                    path = `surveyPages.${i}.surveyPage.${action.path}`;
                                    newVal = [];
                                    if (action.fld.name === 'id') {
                                        newVal = action.data;
                                    } else if (action.fld.name !== 'surveyItems') {
                                        newVal = _.get(action.data, action.path);
                                    } else {
                                        // action.data.surveyItems.forEach(surveyItem => {
                                        //     console.log(
                                        //         'Need to try to find loaded data for surveyItem id:',
                                        //         surveyItem.surveyItem
                                        //     );
                                        //     const storedItem = surveyPage.surveyPage.surveyItems?.find(
                                        //         item => item.surveyItem.id === surveyItem.surveyItem
                                        //     );
                                        //     console.log('Found:', storedItem);
                                        //     if (storedItem) newVal.push(storedItem);
                                        // });
                                        surveyPage.surveyPage.surveyItems?.forEach(surveyItem => {
                                            console.log(
                                                'Need to try to find loaded data for surveyItem id:',
                                                surveyItem.surveyItem
                                            );
                                            // const storedItem = surveyPage.surveyPage.surveyItems?.find(
                                            //     item => item.surveyItem.id === surveyItem.surveyItem
                                            // );
                                            // console.log('Found:', storedItem);
                                            // if (storedItem) newVal.push(storedItem);
                                        });
                                    }
                                }
                            });
                        }
                    }
                    break;

                case 'surveyItem':
                    console.log('Editor sent surveyItem:', action.id);
                    if (state.config && state.config.surveyPages) {
                        state.config.surveyPages.forEach((surveyPage, i) => {
                            surveyPage.surveyPage.surveyItems?.forEach((item, j) => {
                                if (item.surveyItem.id === action.id) {
                                    path = `surveyPages.${i}.surveyPage.surveyItems.${j}.surveyItem.${action.path}`;
                                    newVal = _.cloneDeep(_.get(action.data, action.path));
                                }
                            });
                        });
                    }
                    break;

                default:
                    console.log('Untrapped subPageId:', action.subPageId);
                    break;
            }

            if (path && newConfig) {
                console.log('path, new val:', path, newVal);
                _.set(newConfig, path, newVal);
                return { ...state, config: newConfig };
            }

            return state;

        case SET_SURVEY_DATA:
            newData[action.location] = _.cloneDeep(action.data);
            return { ...state, data: newData };

        case SET_SURVEY_ERROR:
            // console.log('got error:', action.error);
            newErrors = _.cloneDeep(state.errors) ?? [];
            index = newErrors.findIndex(error => error.path === action.error.path);
            if (index === -1) {
                newErrors.push(action.error);
            } else {
                const oldErr = newErrors[index];
                newErrors[index] = { path: oldErr.path, message: `${oldErr.message} | ${action.error.message}` };
            }

            return { ...state, errors: newErrors };

        case CLEAR_SURVEY_ERROR:
            newErrors = state.errors ? _.cloneDeep(state.errors).filter(error => error.path !== action.path) : null;

            return { ...state, errors: newErrors };

        case SET_SHOW_PAGE_ERRORS:
            newShowPageErrors[action.pageName] = action.show;
            return { ...state, showPageErrors: newShowPageErrors };

        default:
            return state;
    }
};

export default reducer;
