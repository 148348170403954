import {
    ApolloClient,
    ApolloLink,
    // from /*, createHttpLink*/,
    from,
    split,
    NormalizedCacheObject,
    ApolloProvider
    // createHttpLink
} from '@apollo/client';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './sass/main.scss';
// import Cookies from 'js-cookie';
import _ from 'lodash';

import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
// import { createUploadLink } from 'apollo-upload-client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import cache from './cache';

import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { DataProtectorProvider } from './contexts/DataProtector';
// import { PagesProvider } from './contexts/PagesContext';
import { RealtimeProvider } from './contexts/RealtimeContext';

import App from './App';

import mainReducer, { MainState, Versions } from './store/reducers/main';
import navReducer, { NavState } from './store/reducers/nav';
import pagesReducer, { PagesState } from './store/reducers/pages';
import editCombosReducer, { EditCombosState } from './store/reducers/editCombo';
import errorsReducer, { ErrorsState } from './store/reducers/errors';
import authReducer, { AuthState } from './store/reducers/auth';
import commReducer, { CommState } from './store/reducers/comm';
import bigScrollReducer, { BigScrollState } from './store/reducers/bigScroll';
import surveyReducer, { SurveyState } from './store/reducers/survey';
import userReducer, { UserState } from './store/reducers/user';

import C from './helpers/constants';
import * as actions from './store/actions';

const rootReducer = combineReducers({
    main: mainReducer,
    nav: navReducer,
    pages: pagesReducer,
    editCombos: editCombosReducer,
    errors: errorsReducer,
    auth: authReducer,
    comm: commReducer,
    bigScroll: bigScrollReducer,
    survey: surveyReducer,
    user: userReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeWithDevTools());

export type State = {
    main: MainState;
    nav: NavState;
    pages: PagesState;
    editCombos: EditCombosState;
    errors: ErrorsState;
    auth: AuthState;
    comm: CommState;
    bigScroll: BigScrollState;
    survey: SurveyState;
    user: UserState;
};

// const httpLink = createHttpLink({ uri: 'http://localhost/auth/graphql', credentials: 'include' });

// function customFetch(url: any, opts: any = {}) {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest()

//       xhr.open(opts.method || 'get', url)

//       for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

//       xhr.onload = (e: any) =>
//         resolve({
//           ok: true,
//           text: () => Promise.resolve(e.target.responseText),
//           json: () => Promise.resolve(JSON.parse(e.target.responseText))
//         })

//       xhr.onerror = reject

//       if (xhr.upload)
//         xhr.upload.onprogress = event =>
//           console.log(`${event.loaded / event.total * 100}% uploaded`)

//       xhr.send(opts.body)
//     })
//   }

// const parseHeaders = (rawHeaders: any) => {
//     const headers = new Headers();
//     // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
//     // https://tools.ietf.org/html/rfc7230#section-3.2
//     const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
//     preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
//         const parts = line.split(':');
//         const key = parts.shift().trim();
//         if (key) {
//             const value = parts.join(':').trim();
//             headers.append(key, value);
//         }
//     });
//     return headers;
// };

// export const uploadFetch = (url: string, options: any) =>
//     new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();
//         xhr.onload = () => {
//             const opts: any = {
//                 status: xhr.status,
//                 statusText: xhr.statusText,
//                 headers: parseHeaders(xhr.getAllResponseHeaders() || '')
//             };
//             opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
//             const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
//             resolve(new Response(body, opts));
//         };
//         xhr.onerror = () => {
//             reject(new TypeError('Network request failed'));
//         };
//         xhr.ontimeout = () => {
//             reject(new TypeError('Network request failed'));
//         };
//         xhr.open(options.method, url, true);

//         Object.keys(options.headers).forEach(key => {
//             xhr.setRequestHeader(key, options.headers[key]);
//         });

//         if (xhr.upload) {
//             // xhr.upload.onprogress = options.onProgress;
//             xhr.upload.onprogress = () => console.log('onProgress');
//         }

//         // options.onAbortPossible(() => {
//         //     xhr.abort();
//         // });

//         xhr.send(options.body);
//     });

// const customFetch = (uri: any, options: any) => {
//     if (options.useUpload) {
//         return uploadFetch(uri, options);
//     }
//     return fetch(uri, options);
// };

// function customFetch(url: string, opts: any = {}) {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest()

//       xhr.open(opts.method || 'get', url)

//       for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

//       xhr.onload = (e: any) =>
//         resolve({
//           ok: true,
//           text: () => Promise.resolve(e.target.responseText),
//           json: () => Promise.resolve(JSON.parse(e.target.responseText))
//         })

//       xhr.onerror = reject

//       if (xhr.upload)
//         xhr.upload.onprogress = event =>
//           console.log(`${event.loaded / event.total * 100}% uploaded`)

//       xhr.send(opts.body)
//     })
//   }
// //   const uploadLink = createUploadLink({ uri: 'http://localhost/auth/graphql', credentials: 'include' });
// const uploadLink = createUploadLink({
//     uri: 'http://localhost/auth/graphql',
//     credentials: 'include',
//     // fetch: typeof window === 'undefined' ? global.fetch : customFetch
//     fetch: customFetch('http://localhost/auth/graphql', { method: 'POST' }) as any
// });

// After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.

const parseHeaders = (rawHeaders: string) => {
    const headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
        const parts = line.split(':');
        const key = parts.shift()?.trim();
        if (key) {
            const value = parts.join(':').trim();
            headers.append(key, value);
        }
    });
    return headers;
};

interface Options extends RequestInit {
    useUpload: boolean;
    onProgress: (progressEvent: ProgressEvent) => void;
}

export const uploadFetch: (url: string, options: Options) => Promise<Response> = (url: string, options: Options) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const opts: { url: string | null; status: number; statusText: string; headers: Headers } = {
                url: null,
                status: xhr.status,
                statusText: xhr.statusText,
                headers: parseHeaders(xhr.getAllResponseHeaders() || '')
            };
            opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
            const body = 'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText;
            resolve(new Response(body, opts));
        };
        xhr.onerror = () => reject(new TypeError('Network request failed'));
        xhr.ontimeout = () => reject(new TypeError('Network request failed'));

        if (options.method) {
            xhr.open(options.method, url, true);
        } else {
            console.log('No method');
        }

        if (options.headers) {
            // Object.keys(options.headers).forEach(key => xhr.setRequestHeader(key, options.headers[key]));
            // Object.keys(options.headers).forEach(header => xhr.setRequestHeader(header[0], header[1]));
            Object.keys(options.headers).forEach(key => {
                // console.log('key, options.headers:', key, options.headers);
                const val = options.headers ? options.headers[key as keyof HeadersInit] : '';
                // console.log('key, val:', key, val);
                xhr.setRequestHeader(key, val as string);
            });
        } else {
            console.log('No headers');
        }

        if (xhr.upload) xhr.upload.onprogress = options.onProgress;

        // options.onAbortPossible(() => xhr.abort());

        xhr.send(options.body as XMLHttpRequestBodyInit);
        // xhr.send(JSON.stringify(options.body));
    });

// console.log('HERE!');
// const domain = process.env.NODE_ENV === 'production' ? 'https://api.gratiaclub.com' : 'http://localhost';
// console.log('domain:', domain);
console.log('REACT_APP_VERSION:', process.env.REACT_APP_VERSION);

const customFetch = (uri: string, options: Options) => {
    if (options.useUpload) return uploadFetch(`${C.apiDomain}/auth/upload`, options);
    return fetch(uri, options);
};

const uploadLink = createUploadLink({
    uri: `${C.apiDomain}/auth/graphql`,
    // credentials: 'same-origin',
    credentials: 'include',
    fetch: customFetch
});

const wsLink = new GraphQLWsLink(
    createClient({
        // url: `${C.apiDomain}/graphql`
        // url: 'ws://localhost/auth/graphql'
        // url: 'wss://api.gratiaclub.com/auth/graphql'
        url: C.wsUrl
    })
);

// const l = createHttpLink({
//     uri: 'http://localhost/auth/graphql',
//     fetch: customFetch as any
// });

const afterwareLink = new ApolloLink((operation, forward) =>
    // console.log('afterwareLink');
    forward(operation).map(response => {
        // console.log('in forward, response:', response);
        const context = operation.getContext();
        // console.log('context:', context);
        const {
            response: { headers }
        } = context;

        // console.log('headers:', headers, typeof headers, JSON.stringify(headers));
        // console.log('TEST2:', headers.get('TEST2'));
        if (headers) {
            // console.log('headers:', headers);
            const xVer = headers.get('x-ver');
            if (xVer) {
                // console.log('x-ver:', xVer);
                // console.log('comparing with:', store.getState().main.versions);
                const versions = JSON.parse(xVer) as Versions;
                if (!_.isEqual(versions, store.getState().main.versions)) store.dispatch(actions.setVersions(versions));
            }

            const prevAlert = store.getState().comm.alert;
            const xAlert = headers.get('x-alert');
            if (xAlert) {
                console.log('x-alert:', xAlert);
                const alert = JSON.parse(xAlert);
                if (!_.isEqual(alert, prevAlert)) store.dispatch(actions.setAlert(alert));
            } else if (prevAlert) {
                store.dispatch(actions.setAlert(null));
            }

            // const refreshToken = headers.get('refreshToken');
            // if (refreshToken) {
            //     localStorage.setItem(AUTH_TOKEN, refreshToken);
            // }
        } else {
            console.log('no headers');
        }

        return response;
    })
);

// const authLink = setContext((_, { headers }) => {
//     // read the token data from the cookie
//     const aTData = Cookies.get('_at');
//     console.log(`At ${Date.now()}, authLink read _at:`, aTData);
//     // return the headers to the context so httpLink can read them
//     return { headers: { ...headers, authorization: aTData ? 'Bearer ' + aTData : '' } };
// });

const metricsLink = setContext((__: unknown, { headers }) => {
    const { versions, screenDims } = store.getState().main;
    const metricsObj = {
        v: { a: versions?.api, m: versions?.min, r: versions?.rec },
        s: { w: screenDims.width, h: screenDims.height },
        t: new Date().getTimezoneOffset()
    };
    return { headers: { ...headers, 'x-metrics': JSON.stringify(metricsObj) } };
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    // httpLink
    // from([afterwareLink, authLink, errorLink, responseLogger, uploadLink])
    // from([afterwareLink, authLink, uploadLink])
    from([afterwareLink, metricsLink, uploadLink])
);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    // link: authLink.concat(uploadLink),
    // link: from([afterwareLink, authLink.concat(uploadLink)]),
    // link: from([afterwareLink, uploadLink]),
    // link: from([afterwareLink, metricsLink.concat(uploadLink)]),
    // link: from([afterwareLink, metricsLink, uploadLink]),
    link: splitLink,
    // link: afterwareLink.concat(authLink),
    // link: afterwareLink.concat(authLink),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    connectToDevTools: true
});

console.log('NODE_ENV:', process.env.NODE_ENV);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    {/* <PagesProvider> */}
                    <DataProtectorProvider>
                        <RealtimeProvider>
                            <App />
                        </RealtimeProvider>
                    </DataProtectorProvider>
                    {/* </PagesProvider> */}
                </Provider>
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
