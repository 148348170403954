import { Reducer } from 'redux';

import _ from 'lodash';

import { SET_ERROR, SET_ERRORS, CLEAR_ERROR, CLEAR_ERRORS } from '../actions/actionTypes';

const errorGroupNames = ['authForm', 'genPasswordResetCode', 'updatePassword', 'popup'] as const;
export type ErrorGroupName = (typeof errorGroupNames)[number];
export type ErrorObj = {
    fieldName?: string;
    id?: string;
    status?: number;
    message: string;
};

export type ErrorsState = { [key in ErrorGroupName]?: ErrorObj[] };

export type ErrorsAction =
    | { type: 'SET_ERROR'; groupName: ErrorGroupName; error: ErrorObj }
    | { type: 'SET_ERRORS'; groupName: ErrorGroupName; errors: ErrorObj[] }
    | { type: 'CLEAR_ERROR'; groupName: ErrorGroupName; fieldName?: string; id?: string }
    | { type: 'CLEAR_ERRORS'; groupName: ErrorGroupName; fieldName?: string };

const initialState = {};

const reducer: Reducer<ErrorsState, ErrorsAction> = (state = initialState, action) => {
    let groupErrors: ErrorObj[] = _.cloneDeep(state[action.groupName]) ?? [];

    switch (action.type) {
        case SET_ERROR:
            if (!groupErrors.some(e => e.fieldName === action.error.fieldName && e.message === action.error.message))
                groupErrors.push(action.error);
            return { ...state, [action.groupName]: groupErrors };

        case SET_ERRORS:
            return { ...state, [action.groupName]: action.errors };

        case CLEAR_ERROR:
            groupErrors = groupErrors.filter(e => e.fieldName !== action.fieldName && e.id !== action.id);
            return { ...state, [action.groupName]: groupErrors.length > 0 ? groupErrors : undefined };

        case CLEAR_ERRORS:
            groupErrors = groupErrors.filter(e => e.fieldName !== action.fieldName);
            return { ...state, [action.groupName]: groupErrors.length > 0 ? groupErrors : undefined };

        default:
            return state;
    }
};

export default reducer;
