import * as actionTypes from './actionTypes';

import { ErrorGroupName, ErrorObj, ErrorsAction } from '../reducers/errors';

export const setError = ({ groupName, error }: { groupName: ErrorGroupName; error: ErrorObj }): ErrorsAction => ({
    type: actionTypes.SET_ERROR,
    groupName,
    error
});

export const setErrors = ({ groupName, errors }: { groupName: ErrorGroupName; errors: ErrorObj[] }): ErrorsAction => ({
    type: actionTypes.SET_ERRORS,
    groupName,
    errors
});

export const clearError = ({
    groupName,
    fieldName,
    id
}: {
    groupName: ErrorGroupName;
    fieldName?: string;
    id?: string;
}): ErrorsAction => ({
    type: actionTypes.CLEAR_ERROR,
    groupName,
    fieldName,
    id
});

export const clearErrors = ({
    groupName,
    fieldName
}: {
    groupName: ErrorGroupName;
    fieldName?: string;
}): ErrorsAction => ({
    type: actionTypes.CLEAR_ERRORS,
    groupName,
    fieldName
});
