import { Reducer } from 'redux';
import _ from 'lodash';

import { getPages_getPages as PageType } from '../../helpers/__types__/getPages';

// prettier-ignore
// import { SET_CURR_PAGE_NUM, SET_CURR_SUBPAGE, SET_TRIGGER, UPDATE_TRIGGER_STATUS, CLEAR_TRIGGER } from '../actions/actionTypes';
import { SET_PAGES, SET_CURR_PAGE_NUM, SET_TRIGGER, UPDATE_TRIGGER_STATUS, CLEAR_TRIGGER } from '../actions/actionTypes';
// import { pages } from '../../contexts/pageData';

import { DataType } from '../../__types__/graphql-global-types';

// import { EditComboId, SubPageId, SubPage } from '../../types/ui/EditCombo';
// import { SubPageId } from '../../types/ui/EditCombo';
import { UserMode } from './auth';

// prettier-ignore
// export type PageName = 'home' | 'user' | 'meeting' | 'messaging' | 'onboarding' | 'venue' | 'home' | 'what' | 'who' | 'why' | 'how' | 'conduct' | 'contact' | 'members';
// export type PageName = 'home' | 'user';
// export type PageName = 'home' | 'user' | 'matching' | 'meet' | 'messaging' | 'onboarding' | 'venue' | 'custService' | 'reports' | 'job' | 'settings' | 'diag';
// export type SubPageId =
//     | 'edit'
//     | 'history'
//     | 'post'
//     | 'dm'
//     | 'broadcast'
//     | 'surveyItem'
//     | 'surveyPage'
//     | 'survey'
//     | 'inviteCode'
//     | 'initialize';

// export enum DataType {
//     User = 'User',
//     SurveyItem = 'SurveyItem',
//     SurveyPage = 'SurveyPage',
//     Survey = 'Survey',
//     Venue = 'Venue',
//     Post = 'Post',
//     InviteCode = 'InviteCode',
//     EmailTemplate = 'EmailTemplate'
// }

// export type PageType =
//     // | { type: 'panels'; title: string; subPages?: any; panels: { [key: string]: any } }
//     | { type: 'editCombo'; title: string; editComboId: EditComboId; subPages: { [key in SubPageId]: SubPage } }
//     | { type: 'empty'; title: string };

export type Page = PageType;

// export type TriggerAction = 'selPage' | 'selSubPage' | 'initObj' | 'selObj' | 'saveObj' | 'delObj';
export type TriggerAction = Trigger['action'];
export type TriggerStatus = 'offerSaveIfChanged' | 'confirm' | 'execSave' | 'proceed' | 'error';

// type TriggerTypeFull = { pageNum: number; subPageId: SubPageId; objId: string; dataType: DataType; status: TriggerStatus };

export type Trigger =
    | { status: TriggerStatus; action: 'selPageNum'; pageNum: number }
    | { status: TriggerStatus; action: 'selSubPageName'; subPageName: string }
    | { status: TriggerStatus; action: 'selObjId'; objId: string }
    | { status: TriggerStatus; action: 'multiSelObjId'; objId: string }
    | { status: TriggerStatus; action: 'revert' }
    | { status: TriggerStatus; action: 'initObj'; dataType: DataType }
    | { status: TriggerStatus; action: 'dupeObj'; dataType: DataType; objId: string }
    | { status: TriggerStatus; action: 'saveObj'; dataType: DataType }
    | { status: TriggerStatus; action: 'delObjId'; dataType: DataType; objId: string };

// prettier-ignore
// export type TriggerOld =
//     | { action: 'selPage'; pageNum: number; subPageId?: SubPageId; objId?: string; dataType?: DataType; status?: TriggerStatus }
//     | { action: 'selSubPageId'; pageNum?: number; subPageId: SubPageId; objId?: string; dataType?: DataType; status?: TriggerStatus }
//     | { action: 'selObj'; pageNum?: number; subPageId?: SubPageId; objId: string; dataType?: DataType; status?: TriggerStatus }
//     | { action: 'initObj'; pageNum?: number; subPageId?: SubPageId; objId?: string; dataType: DataType; status?: TriggerStatus }
//     | { action: 'saveObj'; pageNum?: number; subPageId?: SubPageId; objId?: string; dataType: DataType; status?: TriggerStatus }
//     | { action: 'delObj'; pageNum?: number; subPageId?: SubPageId; objId: string; dataType: DataType; status?: TriggerStatus };

// export type Page =
//     | { type: 'panels'; name: string; label: string }
//     | { type: 'editCombo'; name: string; label: string; editComboId: EditComboId }
//     | { type: 'empty'; name: string; label: string };

export type PagesState = {
    // pages?: { [key in PageName]: Page };
    pages: Page[];
    currPageNum: {
        normal: number;
        admin: number;
    };
    // currSubPage: { [key: string]: SubPageId };
    trigger?: Trigger;
};

const initialState: PagesState = {
    // pages: {
    //     home: {
    //         type: 'panels',
    //         name: 'home',
    //         label: 'Home'
    //     },
    //     user: {
    //         type: 'editCombo',
    //         name: 'user',
    //         label: 'Users',
    //         editComboId: 'user'
    //     }
    // },
    pages: [],
    currPageNum: {
        normal: 0,
        admin: 0
    }
    // currSubPage: {}
};

export type PagesAction =
    | { type: 'SET_PAGES'; pages: Page[] }
    | { type: 'SET_CURR_PAGE_NUM'; userMode: UserMode; pageNum: number }
    // | { type: 'SET_CURR_SUBPAGE'; pageName: PageName; subPageId: SubPageId }
    | { type: 'SET_TRIGGER'; trigger: Trigger }
    | { type: 'UPDATE_TRIGGER_STATUS'; status: TriggerStatus }
    | { type: 'CLEAR_TRIGGER' };

const reducer: Reducer<PagesState, PagesAction> = (state = initialState, action) => {
    let newTrigger: Trigger;
    const newCurrPage = _.cloneDeep(state.currPageNum);
    // const newCurrSubpage = { ...state.currSubPage };
    // const newCurrSubPage = _.cloneDeep(state.currSubPage);
    const newState = { ...state };

    switch (action.type) {
        case SET_PAGES:
            return { ...state, pages: _.cloneDeep(action.pages) };

        case SET_CURR_PAGE_NUM:
            newCurrPage[action.userMode] = action.pageNum;

            // if (pages[action.pageName].subPages && !newCurrSubPage[action.pageName])
            //     newCurrSubPage[action.pageName] = Object.keys(pages[action.pageName].subPages)[0] as SubPageId;

            // return { ...state, currPage: newCurrPage, currSubPage: newCurrSubPage };
            return { ...state, currPageNum: newCurrPage };

        // case SET_CURR_SUBPAGE:
        //     // newCurrSubpage[action.pageName] = action.subPageId;
        //     newCurrSubPage[action.pageName] = action.subPageId;

        //     // return { ...state, currSubPage: newCurrSubpage };
        //     return { ...state, currSubPage: newCurrSubPage };

        case SET_TRIGGER:
            return { ...state, trigger: { ...action.trigger } };

        case UPDATE_TRIGGER_STATUS:
            if (state.trigger) {
                newTrigger = { ...state.trigger };
                newTrigger.status = action.status;
                return { ...state, trigger: newTrigger };
            }

            return { ...state };

        case CLEAR_TRIGGER:
            delete newState.trigger;
            return { ...newState };

        default:
            return state;
    }
};

export default reducer;
