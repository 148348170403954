import { Reducer } from 'redux';

import {
    SET_BIGSCROLL_GENDER,
    SET_BIGSCROLL_NAV_HEIGHT,
    SET_BIGSCROLL_HEIGHT,
    SET_BIGSCROLL_AMT,
    JUMP_TO_SECTION
} from '../actions/actionTypes';

export type PageName = 'home' | 'user';

export type Gender = 'm' | 'f';

export type BigScrollState = {
    bigScrollGender: Gender;
    bigScrollNavHeight: number;
    bigScrollHeight: number;
    bigScrollAmt: number;
    currSectionNum: number;
    currSectionFocus: number;
    closestSectionNum: number;
    jumpToSection: string | null;
};

export type BigScrollAction =
    | { type: 'SET_BIGSCROLL_GENDER'; gender: Gender }
    | { type: 'SET_BIGSCROLL_NAV_HEIGHT'; height: number }
    | { type: 'SET_BIGSCROLL_HEIGHT'; height: number }
    | { type: 'SET_BIGSCROLL_AMT'; scrollAmt: number }
    | { type: 'JUMP_TO_SECTION'; sectionURI: string | null };

const initialState = {
    bigScrollGender: 'm' as Gender,
    bigScrollNavHeight: 50,
    bigScrollHeight: 300,
    bigScrollAmt: 0,
    currSectionNum: 0,
    currSectionFocus: 1,
    closestSectionNum: 0,
    jumpToSection: null
};

const setBigScrollAmt = (
    state: BigScrollState,
    action: Extract<BigScrollAction, { type: 'SET_BIGSCROLL_AMT' }>
): BigScrollState => {
    const bigScrollAmt = action.scrollAmt;
    const { bigScrollHeight } = state;

    const currSectionNum = Math.floor(bigScrollAmt / bigScrollHeight);
    const currSectionFocus = 1 - (bigScrollAmt % bigScrollHeight) / bigScrollHeight;
    const closestSectionNum = Math.round(currSectionNum + (1 - currSectionFocus));

    return { ...state, bigScrollAmt, currSectionNum, currSectionFocus, closestSectionNum };
};

const reducer: Reducer<BigScrollState, BigScrollAction> = (state = initialState, action) => {
    switch (action.type) {
        case SET_BIGSCROLL_GENDER:
            return { ...state, bigScrollGender: action.gender };

        case SET_BIGSCROLL_NAV_HEIGHT:
            return { ...state, bigScrollNavHeight: action.height };

        case SET_BIGSCROLL_HEIGHT:
            return { ...state, bigScrollHeight: action.height };

        case SET_BIGSCROLL_AMT:
            return setBigScrollAmt(state, action);

        case JUMP_TO_SECTION:
            return { ...state, jumpToSection: action.sectionURI };

        default:
            return state;
    }
};

export default reducer;
