// import { PageName } from '../reducers/pages';
import {
    // DataObj,
    EditComboName,
    SubPageName,
    // PanelType,
    PanelPtr,
    DataLocation,
    Fld,
    FldData,
    ChooserConfig,
    ChooserData,
    EditorConfig,
    EditorData,
    EditComboStatus,
    SubPageStatus,
    EditCombo
} from '../../types/ui/EditCombo';
import { EditComboAction } from '../reducers/editCombo';

import * as actionTypes from './actionTypes';

export const setEditCombo = (editCombo: EditCombo): EditComboAction => ({
    type: actionTypes.SET_EDIT_COMBO,
    editCombo
});

export const setCurrSubPageName = (editComboName: EditComboName, subPageName: SubPageName): EditComboAction => ({
    type: actionTypes.SET_CURR_SUBPAGE_NAME,
    editComboName,
    subPageName
});

// export const setChooserSelectedId = (
//     editComboName: EditComboName,
//     subPageName: SubPageName,
//     itemId: string
// ): EditComboAction => ({
//     type: actionTypes.SET_CHOOSER_SELECTED_ID,
//     editComboName,
//     subPageName,
//     itemId
// });

// export const setChooserMultiSelIds = (
//     editComboName: EditComboName,
//     subPageName: SubPageName,
//     itemIds: string[]
// ): EditComboAction => ({
//     type: actionTypes.SET_CHOOSER_MULTI_SEL_IDS,
//     editComboName,
//     subPageName,
//     itemIds
// });

export const setStatus = (editComboName: EditComboName, statusObj: EditComboStatus): EditComboAction => ({
    type: actionTypes.SET_STATUS,
    editComboName,
    statusObj
});

export const setSubPageStatus = (panelPtr: PanelPtr, statusObj: Partial<SubPageStatus>): EditComboAction => ({
    type: actionTypes.SET_SUBPAGE_STATUS,
    panelPtr,
    statusObj
});

export const setChooserConfig = (panelPtr: PanelPtr, chooserConfig: ChooserConfig): EditComboAction => ({
    type: actionTypes.SET_CHOOSER_CONFIG,
    panelPtr,
    chooserConfig
});

export const setEditorConfig = (panelPtr: PanelPtr, editorConfig: EditorConfig): EditComboAction => ({
    type: actionTypes.SET_EDITOR_CONFIG,
    panelPtr,
    editorConfig
});

export const setChooserData = (panelPtr: PanelPtr, chooserData: ChooserData | null): EditComboAction => ({
    type: actionTypes.SET_CHOOSER_DATA,
    panelPtr,
    chooserData
});

export const setEditorData = (
    panelPtr: PanelPtr,
    location: DataLocation,
    editorData: EditorData['db'] | null
): EditComboAction => ({ type: actionTypes.SET_EDITOR_DATA, panelPtr, location, editorData });

export const updateEditorData = (
    panelPtr: PanelPtr,
    location: DataLocation,
    path: string,
    data: FldData
): EditComboAction => ({
    type: actionTypes.UPDATE_EDITOR_DATA,
    panelPtr,
    location,
    path,
    data
});

// export const setEditComboData = (
//     editComboName: EditComboName,
//     subPageName: SubPageName,
//     panel: PanelType,
//     dataLocation: DataLocation,
//     dataName: string,
//     data: DataObj
// ): EditComboAction => ({
//     type: actionTypes.SET_EDIT_COMBO_DATA,
//     editComboName,
//     subPageName,
//     panel,
//     dataLocation,
//     dataName,
//     data
// });

export const setPersonalityFlds = (panelPtr: PanelPtr, flds: Fld[]): EditComboAction => ({
    type: actionTypes.SET_PERSONALITY_FLDS,
    panelPtr,
    flds
});

// export const toggleChooserSelect = (editComboName: EditComboName, subPageName: SubPageName, data: string): EditComboAction => ({
//     type: actionTypes.TOGGLE_CHOOSER_SELECT,
//     editComboName,
//     subPageName,
//     data
// });

export const addArrayItem = (panelPtr: PanelPtr, path: string, fld: Fld): EditComboAction => ({
    type: actionTypes.ADD_ARRAY_ITEM,
    panelPtr,
    path,
    fld
});

export const deleteArrayItem = (panelPtr: PanelPtr, path: string, fld: Fld): EditComboAction => ({
    type: actionTypes.DELETE_ARRAY_ITEM,
    panelPtr,
    path,
    fld
});

export const revertEditorData = (panelPtr: PanelPtr, path?: string): EditComboAction => ({
    type: actionTypes.REVERT_EDITOR_DATA,
    panelPtr,
    path
});
