import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

import C from '../helpers/constants';
import * as actions from '../store/actions';

import { State } from '../index';

// import { ChannelName /*, RoomNames*/ } from '../store/reducers/comm';
import { ChannelName } from '../store/reducers/comm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSockets = (): any => {
    const dispatch = useDispatch();

    const sockets = useSelector((state: State) => state.comm.sockets);
    const connecting = useSelector((state: State) => state.comm.socketConnecting);
    const aT = useSelector((state: State) => state.auth.aTData);
    const userId = aT?.id;

    const initSockets = () => {
        connectSocket('/');
        connectSocket('/admin');
    };

    const connectSocket = useCallback(
        (channelName: ChannelName) => {
            // const domain = process.env.NODE_ENV === 'production' ? 'https://api.gratiaclub.com' : 'http://localhost';
            const url = `${C.apiDomain}${channelName}`;
            console.log(`socketManager.connectSocket ${channelName} - url: ${url}, userId: ${userId}`);

            dispatch(actions.setSocketConnecting(channelName, true));
            const socket = io(url, {
                path: '/auth/s/socket.io',
                auth: { userId: channelName === '/adminSignedIn' ? userId : null }
            });

            socket.on('connect', () => {
                console.log(`socketManager.on connect: connected ${channelName}, userId: ${userId}`);

                if (!sockets[channelName]) {
                    dispatch(actions.setSocket(channelName, socket));

                    // if (channelName === '/adminSignedIn') {
                    //     dispatch(actions.setSignedIn(true));
                    //     console.log('NEED TO LOAD USER');
                    // }

                    socket.onAny((eventName, eventMessage) =>
                        dispatch(actions.setSocketEventData(channelName, eventName, eventMessage))
                    );

                    dispatch(actions.setSocketConnecting(channelName, false));
                }

                if (channelName === '/') dispatch(actions.clearErrors({ groupName: 'popup' }));
            });

            socket.on('disconnect', (reason: string) => {
                console.log(`socketManager: .on disconnect ${channelName}:`, reason);

                if (channelName === '/adminSignedIn') {
                    dispatch(
                        // actions.addProcessDebugData('signOut', 'uS - socket /adminSignedIn signed out, signedIn=false')
                        actions.addProcessDebugData('signOut', 'uS - socket /adminSignedIn disconnected')
                    );
                    // dispatch(actions.setSignedIn(false));
                }

                if (reason === 'io server disconnect') {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    console.log("I'm reconnecting automatically!");

                    socket.connect();
                }
                // else the socket will automatically try to reconnect

                if (reason === 'io client disconnect') {
                    console.log('Deleting socket - manual signout:', channelName);
                    dispatch(actions.setSocket(channelName, null));
                } else {
                    console.log('Not deleting socket - might auto-reconnect:', channelName);
                }

                if (channelName === '/') {
                    console.log('Show reconnecting popup');
                    dispatch(
                        actions.setError({ groupName: 'popup', error: { message: 'Reconnecting with server...' } })
                    );
                }
            });
        },
        [sockets, userId, dispatch]
    );

    const socketExists = useCallback((channelName: ChannelName) => !!sockets[channelName], [sockets]);

    const socketConnecting = useCallback((channelName: ChannelName) => connecting[channelName], [connecting]);

    const socketConnected = useCallback(
        (channelName: ChannelName) => (sockets[channelName] ? sockets[channelName]?.connected : false),
        [sockets]
    );

    const disconnectSocket = useCallback(
        (channelName: ChannelName) => {
            const socket = sockets[channelName];

            if (!socket) {
                console.log(`socket-manager.disconnectSocket: channel not found: ${channelName}`);
                return;
            }

            if (socket.connected) {
                console.log(`Disconnecting socket: ${channelName}`);
                socket.disconnect();
            } else {
                console.log(`socket-manager.disconnectSocket: no need to close socket: ${channelName}`);
            }

            console.log('Setting socket to null:', channelName);
            dispatch(actions.setSocket(channelName, null));
        },
        [sockets, dispatch]
    );

    const disconnectAllSockets = useCallback(() => {
        console.log('socketManager.disconnectAllSockets');

        Object.keys(sockets).forEach(socketName => {
            const channelName = socketName as ChannelName;
            console.log('-->', channelName);
            const socket = sockets[channelName];
            if (socket) socket.disconnect();
            dispatch(actions.setSocket(channelName, null));
        });
    }, [sockets, dispatch]);

    // prettier-ignore
    return { sockets, socketExists, socketConnecting, socketConnected, initSockets, connectSocket, disconnectSocket, disconnectAllSockets };
};

export default useSockets;
