const constants = {
    apiDomain: process.env.NODE_ENV === 'production' ? 'https://api.gratiaclub.com' : 'http://localhost',

    wsUrl:
        process.env.NODE_ENV === 'production' ? 'wss://api.gratiaclub.com/auth/graphql' : 'ws://localhost/auth/graphql',

    cookieDomain: process.env.NODE_ENV === 'production' ? '.gratiaclub.com' : 'localhost'
};

export default constants;
