import * as actionTypes from './actionTypes';
import { Gender, BigScrollAction } from '../reducers/bigScroll';

export const setBigScrollGender = (gender: Gender): BigScrollAction => ({
    type: actionTypes.SET_BIGSCROLL_GENDER,
    gender
});

export const setBigScrollNavHeight = (height: number): BigScrollAction => ({
    type: actionTypes.SET_BIGSCROLL_NAV_HEIGHT,
    height
});

export const setBigScrollHeight = (height: number): BigScrollAction => ({
    type: actionTypes.SET_BIGSCROLL_HEIGHT,
    height
});

export const setBigScrollAmt = (scrollAmt: number): BigScrollAction => ({
    type: actionTypes.SET_BIGSCROLL_AMT,
    scrollAmt
});

export const jumpToSection = (sectionURI: string | null): BigScrollAction => ({
    type: actionTypes.JUMP_TO_SECTION,
    sectionURI
});
