import { gql } from '@apollo/client';

// prettier-ignore
// const frgFldPropertiesAll = gql`
//     fragment fldPropertiesAll on Fld { name label type itemName itemLabel funcName isArray minArraySize maxArraySize dataSrc itemSummary
//         itemDetails { el class contents }
//         vals { label intVal floatVal strVal boolVal } defaultVal
//         required dateTimeRules multiSelectFILO showIf hidden readOnly
//     }`;

// prettier-ignore
const editComboSubPagePropertiesAll = gql`
    fragment subPagelAll on SubPage {
        name label itemSummary itemSummarySimple
        panels { panelGrpName attrs { componentType dataType editorType version mode title } }
    }
`;

// prettier-ignore
const surveyItemPropertiesAll = gql`
    fragment surveyItemAll on SurveyItem {
        id displayName refNum paramName topic perspective matchWithOppositePerspective isPersonality twoColumns optionType validator
            minSelections maxSelections minLength maxLength captionHint required options {
                name text
            } new }
`;

// prettier-ignore
const meetPropertiesAll = gql`
    fragment meetAll on Meet {
        id name
        reservation { id
            venue { provider_venue_id name address cuisine photos { small medium large raw } }
             requestedDateTimeL actualDateTimeL nameOnReservation status
        }
        participants { user { id memberName } paying statuses { name val } }
        location origRequestedLocation messages { type text expiresL } status new createdAt updatedAt }`;

// prettier-ignore
const Fragments = {
    pagePropertiesAll: gql`
        fragment pageAll on Page { name label type panelTypes func reqPermissions }`,

    editComboPropertiesAll: gql`
        fragment editComboAll on EditCombo {
            name label subPages { ...subPagelAll } status { currSubPageName changed }
        } ${editComboSubPagePropertiesAll}
    `,

    userPropertiesAll: gql`
        fragment userAll on User {
            id memberName email hasPassword passwordResetCode passwordResetCodeExpires tempMemberName hasTempPassword tempPassword tempPasswordDisplay groups
            membership { flags
                journals {
                    dollars {
                        currency category method amount memo enteredByUser { id memberName } stripeIn {
                            cart { items { sku name quantity price } } amount
                            stripe { paymentIntent { id client_secret last_payment_error status created livemode } } createdAt updatedAt
                        }
                        createdAt updatedAt
                    }
                    meets { currency category method amount memo enteredByUser { id memberName } refMeet { id } expiresL createdAt updatedAt }
                }
                status { dollars meets meetsExpireL }
            }
            inviteCode referredBy gender targetGender
            paying surveyId onboardingStatus accepted prefix firstName middleName lastName instagramHandle phone phoneVerificationCode phoneVerified
            expoPushToken birthdayL height permissions userMode approved active hidden
            repImages { url origFileName fileName tempFileName title caption alt }
            realImages { url origFileName fileName tempFileName title caption alt }
            preferences { name val isDefault }
            correspondence { method purpose recipients subject body status metadata createdAt }
            unreads { objType objId }
            devices { expoPushToken deviceInfoJSON }
            links personality new }`,

    surveyPropertiesAll: gql`fragment surveyAll on Survey {
        id name disabled surveyPages { surveyPage showForGenders allowSkip includeInSummary } new 
    }`,

    surveyPagePropertiesAll: gql`fragment surveyPageAll on SurveyPage {
        id name refNum type style heading subHeading surveyItems { surveyItem leftLabel topLabels } infoBox new
    }`,

    venuePropertiesAll: gql`fragment venueAll on Venue { id name address { street city state postalCode geo } phones { name areaCode phoneNumber } descShort descLong parentId openTableId new }`,

    postPropertiesAll: gql`fragment postAll on Post { id
        message { headline contents creator likes dislikes }
        comments { headline contents creator likes dislikes } new
        }`,

    inviteCodePropertiesAll: gql`fragment inviteCodeAll on InviteCode { id name code gender targetGender source sourceID new }`,

    infoBoxPropertiesAll: gql`fragment infoBoxAll on InfoBox {
        id name displayName refNum style html actionBtnType actionBtnText mustAccept acceptBtnText acceptBtnSelText rejectBtnText rejectBtnSelText new
    }`,

    matchRuleSetPropertiesAll: gql`fragment matchRuleSetAll on MatchRuleSet { id name refNum matchRules { matchRule weight } new }`,

    matchRulePropertiesAll: gql`fragment matchRuleAll on MatchRule {
        id name displayName refNum basis path surveyItem topic algorithm baseGender ansWeights { ans weight }
        ageDeltaLow ageDeltaHigh ageDir heightDeltaLow heightDeltaHigh heightDir new
    } ${surveyItemPropertiesAll}`,

    jobPropertiesAll: gql`fragment jobAll on Job { id name refNum triggerType triggerVal dataModel filters { paramName operator vals } processType processId schedule new }`,

    emailTemplatePropertiesAll: gql`fragment emailTemplateAll on EmailTemplate { id name displayName refNum subject rawHTML body new }`,

    systemPropertiesAll: gql`fragment systemAll on System { id displayName refNum paramName val new }`,

    fldPropertiesAll: gql`
        fragment fldPropertiesAll on Fld {
            name label uiVar type itemName itemLabel funcName subFuncName isArray minArraySize maxArraySize dataSrc itemSummary
            itemDetails { el class contents }
            vals { label intVal floatVal strVal boolVal } defaultVal
            required dateTimeRules multiSelectFILO showIf hidden readOnly
        }`
};

// prettier-ignore
export const Q = {
    PING: gql`query ping { ping }`,

    GET_PAGES: gql`query getPages { getPages { ...pageAll } } ${Fragments.pagePropertiesAll}`,

    GET_EDIT_COMBO: gql`query editCombo($name: String!) { editCombo(name: $name) { ...editComboAll } } ${Fragments.editComboPropertiesAll}`,

    IS_MEMBER_NAME_IN_USE: gql`query isMemberNameInUse($id: ID, $memberName: String) { isMemberNameInUse(id: $id, memberName: $memberName) }`,

    GEN_PASSWORD_RESET_CODE: gql`query genPasswordResetCode($memberName: String!) { genPasswordResetCode(memberName: $memberName) }`,

    GEN_PHONE_VERIFICATION_CODE: gql`query genPhoneVerificationCode($phoneNum: String) { genPhoneVerificationCode(phoneNum: $phoneNum) }`,

    TEST_PHONE_VERIFICATION_CODE: gql`
        query testPhoneVerificationCode($phoneVerificationCode: String) { testPhoneVerificationCode(phoneVerificationCode: $phoneVerificationCode) }`,

    GET_INDEX: gql`
        query index($dataType: DataType!, $sortName: String, $sortObjs: [SortObj], $filterVal: String, $perPage: Int, $pageNum: Int) {
            index(dataType: $dataType, sortName: $sortName, sortObjs: $sortObjs, filterVal: $filterVal, perPage: $perPage, pageNum: $pageNum) {
                __typename filterVal sortName
                pagination { displayItemCount totalItemCount currPage totalPages }
                items {
                    ... on User { id memberName email firstName lastName birthdayL }
                    ... on UserGroup { id name new }
                    ... on Meet { id name reservation { venue { name } } }
                    ... on SurveyItem { id displayName paramName refNum }
                    ... on SurveyPage { id name refNum heading }
                    ... on Survey { id name }
                    ... on Venue { id name }
                    ... on Post { id message { headline contents } }
                    ... on InviteCode { id name }
                    ... on InfoBox { id name displayName refNum }
                    ... on MatchRuleSet { id name refNum }
                    ... on MatchRule { id name displayName refNum }
                    ... on Job { id name refNum }
                    ... on EmailTemplate { id name displayName subject refNum }
                    ... on System { id displayName refNum paramName }
                }
    } }`,

    GET_CATALOG: gql`
        query catalog($mode: CatalogMode!, $id: ID!) {
            catalog(mode: $mode, id: $id) { name label itemsType items { name label val itemsType items { name label val } } }
        }
    `,

    GET_CHOOSER_CONFIG: gql`
        query chooserConfig($editComboName: String!, $subPageName: String!) {
            chooserConfig(editComboName: $editComboName, subPageName: $subPageName) {
                __typename
                enableNew filterPlaceholder
                sortOptions { name label }
                toggleGroups { label toggles { name label val isDefaultVal groups trueDisablesGroups } }
            }
    }`,

    // GET_CHOOSER_DATA: gql`
    //     query chooserData($editComboId: String, $subPageId: String, $sortName: String, $filterVal: String) {
    //         chooserData(editComboId: $editComboId, subPageId: $subPageId, sortName: $sortName, filterVal: $filterVal) {
    //             __typename
    //             filterVal currSort pagination { displayItemCount totalItemCount currPage totalPages }
    //             items {
    //                 ... on User { id memberName email firstName lastName birthdayL }
    //                 ... on Meeting { id name }
    //                 ... on SurveyItem { id displayName paramName refNum }
    //                 ... on SurveyPage { id name refNum heading }
    //                 ... on Survey { id name }
    //                 ... on Venue { id name }
    //                 ... on Post { id message { headline contents } }
    //                 ... on InviteCode { id name }
    //                 ... on InfoBox { id name displayName refNum }
    //                 ... on MatchRuleSet { id name refNum }
    //                 ... on MatchRule { id name displayName refNum }
    //                 ... on Job { id name refNum }
    //                 ... on EmailTemplate { id displayName subject refNum }
    //                 ... on System { id displayName refNum paramName }
    //             }
    //         }
    // }`,

    GET_OBJ: gql`query obj($dataType: DataType!, $id: ID!) { obj(dataType: $dataType, id: $id) {
        __typename
        ... on User { ...userAll }
        ... on UserGroup { id name new }
        ... on Meet { ...meetAll }
        ... on SurveyItem { ...surveyItemAll }
        ... on SurveyPage { ...surveyPageAll }
        ... on Survey { ...surveyAll }
        ... on Venue { ...venueAll }
        ... on Post { ...postAll }
        ... on InviteCode { ...inviteCodeAll }
        ... on InfoBox { ...infoBoxAll }
        ... on MatchRuleSet { ...matchRuleSetAll}
        ... on MatchRule { ...matchRuleAll}
        ... on Job { ...jobAll }
        ... on EmailTemplate { ...emailTemplateAll }
        ... on System { ...systemAll }
    } }
    ${Fragments.userPropertiesAll} ${meetPropertiesAll} ${surveyItemPropertiesAll} ${Fragments.surveyPagePropertiesAll} ${Fragments.surveyPropertiesAll}
    ${Fragments.venuePropertiesAll} ${Fragments.postPropertiesAll} ${Fragments.inviteCodePropertiesAll} ${Fragments.infoBoxPropertiesAll}
    ${Fragments.matchRuleSetPropertiesAll} ${Fragments.matchRulePropertiesAll} ${Fragments.jobPropertiesAll} ${Fragments.emailTemplatePropertiesAll} ${Fragments.systemPropertiesAll}`,

    GET_EDITOR_CONFIG: gql`
        query editorConfig($editorType: EditorType!, $version: String!) {
            editorConfig(editorType: $editorType, version: $version) {
                sections { name label type showHeader open 
                    flds { ...fldPropertiesAll flds { ...fldPropertiesAll flds { ...fldPropertiesAll flds { ...fldPropertiesAll flds { ...fldPropertiesAll } } } } }
                }
            }
        }
        ${Fragments.fldPropertiesAll}
    `,

    GET_USER_SURVEY: gql`query userSurvey {
            userSurvey {
                id name disabled surveyPages {
                    surveyPage {
                        id name refNum type style heading subHeading surveyItems {
                            surveyItem { ...surveyItemAll } leftLabel topLabels
                        }
                        infoBox { ...infoBoxAll }
                    }
                    showForGenders allowSkip includeInSummary }
            }
        } ${surveyItemPropertiesAll} ${Fragments.infoBoxPropertiesAll}`,

    GET_PAYMENT_INTENT: gql`
        query getPaymentIntent($cart: [CartItemInput]) {
            getPaymentIntent(cart: $cart) { id clientSecret }
        }`,

    CONFIRM_PAYMENT: gql`query confirmPayment($id: String!) { confirmPayment(id: $id) }`,

    MANUAL_FIND_MATCHES: gql`query manualFindMatches($matchRuleSetId: ID!, $userId: ID!) {
        manualFindMatches(matchRuleSetId: $matchRuleSetId, userId: $userId)
    }`,

    TEST_RT_DATA: gql`query testRtData { testRtData }`
};

// prettier-ignore
export const M = {
    AUTH_SIGN_UP_IN: gql`
        mutation signUpIn($mode: SignUpInMode!, $inviteCode: String, $memberName: String, $password: String) {
            signUpIn(mode: $mode, inviteCode: $inviteCode, memberName: $memberName, password: $password) { id email }
        }
    `,

    AUTH_SIGN_OUT: gql`mutation signOut { signOut }`,

    AUTH_RESET_PASSWORD: gql`mutation resetPassword($memberName: String!, $passwordResetCode: String!, $newPassword: String!) {
        resetPassword(memberName: $memberName, passwordResetCode: $passwordResetCode, newPassword: $newPassword)
    }`,

    AUTH_EXTEND_REFRESH_TOKEN: gql`mutation extendRefreshToken { extendRefreshToken }`,

    MODIFY_MEMBERSHIP_FLAG: gql`
        mutation membershipFlag($userId: ID!, $membershipFlag: MembershipFlag!, $action: FlagAction!) {
            membershipFlag(userId: $userId, membershipFlag: $membershipFlag, action: $action) {
                membership { flags }
            }
    }`,

    INVITE: gql`mutation invite($userId: ID!, $emailTemplateId: ID!) {
        invite(userId: $userId, emailTemplateId: $emailTemplateId) {
            success user { ...userAll }
        } } ${Fragments.userPropertiesAll}`,

    APPROVE: gql`mutation approve($userId: ID!) {
        approve(userId: $userId) { success user { ...userAll } } } ${Fragments.userPropertiesAll}`,

    UPLOAD_TEMP_FILE: gql`
        mutation uploadTempFile($fileName: String!, $file: Upload!) {
            uploadTempFile(fileName: $fileName, file: $file) { url origFileName tempFileName }
        }`,

    INIT_OBJ: gql`mutation initObj($dataType: DataType!) { initObj(dataType: $dataType) {
        __typename
        ... on User { ...userAll }
        ... on UserGroup { id name new }
        ... on Meet { ...meetAll }
        ... on SurveyItem { ...surveyItemAll }
        ... on SurveyPage { ...surveyPageAll }
        ... on Survey { ...surveyAll }
        ... on Venue { ...venueAll }
        ... on Post { ...postAll }
        ... on InviteCode { ...inviteCodeAll }
        ... on InfoBox { ...infoBoxAll }
        ... on MatchRuleSet { ...matchRuleSetAll}
        ... on MatchRule { ...matchRuleAll}
        ... on Job { ...jobAll }
        ... on EmailTemplate { ...emailTemplateAll }
        ... on System { ...systemAll }
    } }
    ${Fragments.userPropertiesAll} ${meetPropertiesAll} ${surveyItemPropertiesAll} ${Fragments.surveyPagePropertiesAll} ${Fragments.surveyPropertiesAll}
    ${Fragments.venuePropertiesAll} ${Fragments.postPropertiesAll} ${Fragments.inviteCodePropertiesAll} ${Fragments.infoBoxPropertiesAll}
    ${Fragments.matchRuleSetPropertiesAll} ${Fragments.matchRulePropertiesAll} ${Fragments.jobPropertiesAll} ${Fragments.emailTemplatePropertiesAll} ${Fragments.systemPropertiesAll}`,

    DUPE_OBJ: gql`mutation dupeObj($dataType: DataType!, $id: ID!) { dupeObj(dataType: $dataType, id: $id) {
        __typename
        ... on User { ...userAll }
        ... on UserGroup { id name new }
        ... on Meet { ...meetAll }
        ... on SurveyItem { ...surveyItemAll }
        ... on SurveyPage { ...surveyPageAll }
        ... on Survey { ...surveyAll }
        ... on Venue { ...venueAll }
        ... on Post { ...postAll }
        ... on InviteCode { ...inviteCodeAll }
        ... on InfoBox { ...infoBoxAll }
        ... on MatchRuleSet { ...matchRuleSetAll}
        ... on MatchRule { ...matchRuleAll}
        ... on Job { ...jobAll }
        ... on EmailTemplate { ...emailTemplateAll }
        ... on System { ...systemAll }
    } }
    ${Fragments.userPropertiesAll} ${meetPropertiesAll} ${surveyItemPropertiesAll} ${Fragments.surveyPagePropertiesAll} ${Fragments.surveyPropertiesAll}
    ${Fragments.venuePropertiesAll} ${Fragments.postPropertiesAll} ${Fragments.inviteCodePropertiesAll} ${Fragments.infoBoxPropertiesAll}
    ${Fragments.matchRuleSetPropertiesAll} ${Fragments.matchRulePropertiesAll} ${Fragments.jobPropertiesAll} ${Fragments.emailTemplatePropertiesAll} ${Fragments.systemPropertiesAll}`,

    SAVE_OBJ: gql`mutation saveObj($dataType: DataType!, $data: String!) { saveObj(dataType: $dataType, data: $data) {
        __typename
        ... on User { ...userAll }
        ... on UserGroup { id name new }
        ... on Meet { ...meetAll }
        ... on SurveyItem { ...surveyItemAll }
        ... on SurveyPage { ...surveyPageAll }
        ... on Survey { ...surveyAll }
        ... on Venue { ...venueAll }
        ... on Post { ...postAll }
        ... on InviteCode { ...inviteCodeAll }
        ... on InfoBox { ...infoBoxAll }
        ... on MatchRuleSet { ...matchRuleSetAll}
        ... on MatchRule { ...matchRuleAll}
        ... on Job { ...jobAll }
        ... on EmailTemplate { ...emailTemplateAll }
        ... on System { ...systemAll }
    } }
    ${Fragments.userPropertiesAll} ${meetPropertiesAll} ${surveyItemPropertiesAll} ${Fragments.surveyPagePropertiesAll} ${Fragments.surveyPropertiesAll}
    ${Fragments.venuePropertiesAll} ${Fragments.postPropertiesAll} ${Fragments.inviteCodePropertiesAll} ${Fragments.infoBoxPropertiesAll}
    ${Fragments.matchRuleSetPropertiesAll} ${Fragments.matchRulePropertiesAll} ${Fragments.jobPropertiesAll} ${Fragments.emailTemplatePropertiesAll} ${Fragments.systemPropertiesAll}`,

    SAVE_EDITOR_FLD: gql`
        mutation saveEditorFld($dataType: DataType!, $id: ID!, $path: String!, $val: String!) {
                saveEditorFld(dataType: $dataType, id: $id, path: $path, val: $val)
        }
    `,

    DELETE_OBJ: gql`mutation deleteObj($dataType: DataType!, $id: ID!) { deleteObj(dataType: $dataType, id: $id) }`,

    SET_USER_MODE: gql`mutation setUserMode($userMode: UserMode) { setUserMode(userMode: $userMode) }`,

    SET_PREFERENCE: gql`mutation setPreference($name: String, $val: String) { setPreference(name: $name, val: $val) { name val isDefault } }`,

    TOGGLE_PREFERENCE: gql`mutation togglePreference($name: String) { togglePreference(name: $name) { name val isDefault } }`,

    PRE_MAIL: gql`mutation preMail($rawHTML: String!) { preMail(rawHTML: $rawHTML) }`,

    SEND_EMAIL: gql`mutation sendEmail($type: String!, $sndName: String, $rcvEmail: String!, $message: String!) {
        sendEmail(type: $type, sndName: $sndName, rcvEmail: $rcvEmail, message: $message)
    }`,

    GEN_MATCH_RULES: gql`mutation genMatchRules($surveyId: ID!) { genMatchRules(surveyId: $surveyId) }`,

    TEST_MESSAGING: gql`
        mutation testMessaging($meetId: ID!, $subFuncName: SubFuncName!) {
            testMessaging(meetId: $meetId, subFuncName: $subFuncName)
        }
    `,

    CONVERT_DATES: gql`mutation convertDates { convertDates }`
};

// prettier-ignore
export const S = {
    SUBSCRIBE_RT_DATA: gql`
        subscription subscribeRtData($mode: RtDataMode!, $id: ID!) {
            subscribeRtData(mode: $mode, id: $id) {
                userId mode id
                contents { type controlInfo { command } items { action path columns { className jsonStyle html } } }
            }
        }`
};
