import React, { ReactElement } from 'react';
import _ from 'lodash';
import { differenceInYears } from 'date-fns';

import { ItemDetails } from '../store/reducers/editCombo';
import { User, Preference } from '../store/reducers/user';

export const getPathParent = (path: string): string => {
    const pathSections = path.match(/(.*)(\..*?)$/);
    return pathSections ? pathSections[1] : '';
};

// eslint-disable-next-line
export const cloneDeepNonNull = (d: any): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let output: any = {};

    if (d === null || d === undefined || d === '') return undefined;

    if (Array.isArray(d)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newArr: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        d.forEach((item: any) => {
            if (item !== null && item !== undefined && item !== '') newArr.push(cloneDeepNonNull(item));
        });
        return newArr.length > 0 ? newArr : undefined;
    }

    if (typeof d === 'object') {
        // if object is a date
        if (typeof d.getMonth === 'function') {
            output = d;
        } else {
            Object.keys(d).forEach(key => {
                if (d[key] === null || d[key] === undefined || d[key] === '') return;

                if (Array.isArray(d[key]) || typeof d[key] === 'object') {
                    output[key] = cloneDeepNonNull(d[key]);
                } else {
                    output[key] = d[key];
                }
            });
        }
    } else {
        output = d;
    }

    return _.cloneDeep(output);
};

// eslint-disable-next-line
export const buildDisplayString = (val: any, showInvisibles = false): string => {
    let out = 'buildDisplayString: no string';

    if (val === undefined) {
        out = showInvisibles ? ':undefined:' : '';
    } else if (val === null) {
        out = showInvisibles ? ':null:' : '';
    } else if (typeof val === 'boolean') {
        const visVersion = val ? ':true:' : ':false:';
        out = showInvisibles ? visVersion : '';
    } else if (typeof val === 'object') {
        out = `UNEXPECTED OBJECT FOUND: ${JSON.stringify(val)}`;
    } else {
        out = val;
    }

    return out;
};

export const buildItemSummary = (
    // eslint-disable-next-line
    item: any,
    template?: string[],
    html?: boolean
): string | (string | JSX.Element)[] | JSX.Element => {
    if (!item) return 'No item to build item summary for';
    if (!template) return 'No template to build item summary with';
    let output: string | (string | JSX.Element)[] = '';

    const arr = template.map((t: string, i: number) => {
        const str = t.replace(/<<<(.+?)>>>/g, (m1: string, m2: string) => {
            let birthday;

            switch (m2) {
                case 'age()':
                    birthday = _.get(item, 'birthday');
                    return birthday ? differenceInYears(new Date(), new Date(birthday)) : 'n/a';

                default:
                    return _.get(item, m2);
            }
        });

        return html ? (
            <div key={`${str}-${Math.random().toFixed(5)}`} className={`itemSummary__el itemSummary__el--${i}`}>
                {str}
            </div>
        ) : (
            str
        );
    });

    output = html ? arr : arr.join('-');

    return html ? <div className="itemSummary">{output}</div> : output;
};

// eslint-disable-next-line
export const buildItemDetails = (item?: any, template?: ItemDetails | null) => {
    if (!item) return [<div>No item to build item details for</div>];
    if (!template) return [<div>No template to build item details with</div>];

    const output: ReactElement[] = [];

    template.forEach(templateItem => {
        const val = templateItem.contents.replace(/<<<(.+?)>>>/g, (m1: string, m2: string) => _.get(item, m2));
        // console.log('contents, val:', templateItem.contents, val);

        if (templateItem.el) {
            switch (templateItem.el) {
                case 'div':
                    output.push(
                        <div key={`${val}-${Math.random().toFixed(5)}`} className={templateItem.class ?? ''}>
                            {val}
                        </div>
                    );
                    break;

                case 'span':
                    output.push(
                        <span key={`${val}-${Math.random().toFixed(5)}`} className={templateItem.class ?? ''}>
                            {val}
                        </span>
                    );
                    break;

                default:
                    console.log('utils.buildItemDetails - bad templateItem.el:', templateItem.el);
            }
        } else {
            output.push(<span key={`${val}-${Math.random().toFixed(5)}`}>{val}</span>);
        }
    });

    return output;
};

export const getDateLocal: (dt: Date) => Date = (dt: Date) => {
    const dtStr = new Date(dt).toISOString();
    const newDtStr = dtStr.substring(0, dtStr.length - 1);
    return new Date(newDtStr);
};

export const getPreference: (user: User | null, prefName: string) => Preference | undefined = (
    user: User | null,
    prefName: string
) => {
    if (!user) return undefined;

    const pref = user.preferences ? user.preferences.find(p => p.name === prefName) : undefined;
    return pref?.val ? JSON.parse(pref.val) : undefined;
};
