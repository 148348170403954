import { Reducer } from 'redux';
import _ from 'lodash';

// import { SET_SIGNED_IN, SET_AT_DATA, SET_AT_DISAPPEARED, SET_REMEMBER_ME } from '../actions/actionTypes';
import { SET_SIGNED_IN, SET_AT_DATA, SET_REMEMBER_ME } from '../actions/actionTypes';

export type UserMode = 'normal' | 'admin';
const userModes: { [key: string]: UserMode } = { n: 'normal', a: 'admin' };

export type AccessTokenData = {
    n: number;
    aTExp: number;
    aTRenew: number;
    rTExp: number;
    id: string;
    memberName: string;
    p: string[];
    uM: 'n' | 'a';
};

export type AuthState = {
    signedIn: boolean;
    aTData: AccessTokenData | null;
    // aTDisappeared: boolean;
    rememberMe: boolean;
    userMode: UserMode;
};

export type AuthAction =
    | { type: 'SET_SIGNED_IN'; signedIn: boolean }
    | { type: 'SET_AT_DATA'; aTData: AccessTokenData | null }
    // | { type: 'SET_AT_DISAPPEARED'; aTDisappeared: boolean }
    | { type: 'SET_REMEMBER_ME'; rememberMe: boolean };

const initialState = {
    signedIn: false,
    aTData: null,
    // aTDisappeared: false,
    rememberMe: false,
    userMode: 'normal' as UserMode
};

const reducer: Reducer<AuthState, AuthAction> = (state = initialState, action) => {
    let newATData: AccessTokenData | null;
    let newUserMode: UserMode;

    switch (action.type) {
        case SET_SIGNED_IN:
            return { ...state, signedIn: action.signedIn };

        case SET_AT_DATA:
            newATData = _.cloneDeep(action.aTData);
            newUserMode = newATData ? userModes[newATData.uM] : 'normal';
            return { ...state, aTData: newATData, userMode: newUserMode };

        // case SET_AT_DISAPPEARED:
        //     return { ...state, aTDisappeared: action.aTDisappeared };

        case SET_REMEMBER_ME:
            return { ...state, rememberMe: action.rememberMe };

        default:
            return state;
    }
};

export default reducer;
