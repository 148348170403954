import { ReactElement } from 'react';
import { Reducer } from 'redux';
import { Socket } from 'socket.io-client';
import _ from 'lodash';

import { SET_SOCKET_CONNECTING, SET_SOCKET, SET_SOCKET_EVENT_DATA, SET_ALERT } from '../actions/actionTypes';

export type ChannelName = '/' | '/admin' | '/adminSignedIn';
export type RoomName = 'adminMessagingPage';
export type EventName = 'allClients' | 'adminClients' | 'adminSignedInClients';
export type Alert = {
    level?: 'info' | 'warning' | 'error';
    block?: boolean;
    canClose?: boolean;
    message?: string;
    html?: ReactElement<HTMLDivElement>;
};

export type CommState = {
    socketConnecting: { [key: string]: boolean };
    sockets: { [key: string]: Socket | null };
    // eslint-disable-next-line
    socketEventMessages: { [key: string]: any };
    alert: Alert | null;
};

export type CommAction =
    | { type: 'SET_SOCKET_CONNECTING'; channelName: ChannelName; socketConnecting: boolean }
    | { type: 'SET_SOCKET'; channelName: ChannelName; socket: Socket | null }
    | { type: 'SET_SOCKET_EVENT_DATA'; channelName: ChannelName; eventName: EventName; eventMessage: string }
    | { type: 'SET_ALERT'; alert: Alert | null };

const initialState = {
    socketConnecting: { '/': false, '/admin': false, '/adminSignedIn': false },
    sockets: { '/': null, '/admin': null, '/adminSignedIn': null },
    socketEventMessages: { '/': {}, '/admin': {}, '/adminSignedIn': {} },
    alert: null
};

const reducer: Reducer<CommState, CommAction> = (state = initialState, action) => {
    let newSocketConnecting;
    let newSockets;
    let newMessages;

    switch (action.type) {
        case SET_SOCKET_CONNECTING:
            newSocketConnecting = { ...state.socketConnecting };
            newSocketConnecting[action.channelName] = action.socketConnecting;
            return { ...state, socketConnecting: newSocketConnecting };

        case SET_SOCKET:
            newSockets = { ...state.sockets };
            if (action.channelName)
                newSockets[action.channelName] = action.socket ?? initialState.sockets[action.channelName];

            return { ...state, sockets: newSockets };

        case SET_SOCKET_EVENT_DATA:
            newMessages = _.cloneDeep(state.socketEventMessages);
            newMessages[action.channelName][action.eventName as EventName] = action.eventMessage;

            return { ...state, socketEventMessages: newMessages };

        case SET_ALERT:
            return { ...state, alert: _.cloneDeep(action.alert) };

        default:
            return state;
    }
};

export default reducer;
