import React, { useEffect, useMemo, createContext, useReducer } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { State } from '../index';

type IndicatorType = 'debugATSecs';

type Realtime = {
    aTSecsLeft: number | null;
    rTSecsLeft: number | null;
    triggerReadCookie: boolean;
    indicators: { [key in IndicatorType]: boolean };
    rtDispatch: React.Dispatch<Action>;
};

type Action =
    | { type: 'setSecsLeft'; aTSecsLeft: number | null; rTSecsLeft: number | null }
    | { type: 'setTriggerReadCookie'; triggerReadCookie: boolean }
    | { type: 'setIndicator'; indicatorType: IndicatorType; val: boolean };

const defaultState = {
    aTSecsLeft: null,
    rTSecsLeft: null,
    triggerReadCookie: true,
    indicators: { debugATSecs: false },
    rtDispatch: () => {
        console.log('RealtimeContext - rtDispatch not set');
    }
};

const reducer = (state: Realtime, action: Action) => {
    const newIndicators = _.cloneDeep(state.indicators);

    switch (action.type) {
        case 'setSecsLeft':
            return { ...state, aTSecsLeft: action.aTSecsLeft, rTSecsLeft: action.rTSecsLeft };

        case 'setTriggerReadCookie':
            // console.log(action.triggerReadCookie);
            return { ...state, triggerReadCookie: action.triggerReadCookie };

        case 'setIndicator':
            newIndicators[action.indicatorType] = action.val;
            return { ...state, indicators: newIndicators };

        default:
            console.log('reducer');
    }

    return { ...state };
};

const RealtimeContext = createContext<Realtime>(defaultState);

export const RealtimeProvider: React.FC = props => {
    const [state, rtDispatch] = useReducer(reducer, defaultState);

    const aTData = useSelector((s: State) => s.auth.aTData);

    useEffect(() => {
        let newATSecsLeft: number | null = null;
        let newRtSecsLeft: number | null = null;

        if (aTData) {
            newATSecsLeft = aTData.aTExp - Math.round(aTData.n / 1000);
            newRtSecsLeft = aTData.rTExp - Math.round(aTData.n / 1000);
            // setProcessing(false);
            rtDispatch({ type: 'setSecsLeft', aTSecsLeft: newATSecsLeft, rTSecsLeft: newRtSecsLeft });
        }
    }, [aTData]);

    const value = useMemo(() => ({ ...state, rtDispatch }), [state, rtDispatch]);
    return <RealtimeContext.Provider value={value}>{props.children}</RealtimeContext.Provider>;
};

export default RealtimeContext;
