/* eslint-disable import/prefer-default-export */

// import { SET_NAV_TRIGGER, CLEAR_NAV_TRIGGER, SET_SEL_PAGE } from './actionTypes';
import { SET_SEL_PAGE } from './actionTypes';

import { NavType, NavAction } from '../reducers/nav';

// export const setNavTrigger = (navType: NavType, val: string) => ({ type: SET_NAV_TRIGGER, navType, val });

// export const clearNavTrigger = (navType: NavType) => ({ type: CLEAR_NAV_TRIGGER, navType });

export const setSelPage = (navType: NavType, val: string | null): NavAction => ({ type: SET_SEL_PAGE, navType, val });
